<script>
    import Help from '@ui/Help.vue'
    export default {
        components: { Help },
    }
</script>

<template>
    <ra-stack
        margin="mb-4"
        class="headset-setting-title"
    >
        <ra-typo
            semi
            size="20"
        >
            <ra-stack direction="horizontal">
                <ra-box flex="1">
                    <slot />
                </ra-box>
                <ra-box v-if="'help' in $slots">
                    <help
                        :tooltip-options="{
                            location: 'top end',
                            maxWidth: 400,
                        }"
                    >
                        <slot name="help" />
                    </help>
                </ra-box>
            </ra-stack>
        </ra-typo>
        <v-divider />
    </ra-stack>
</template>
