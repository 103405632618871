<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('groups.affect.title') }}
        </template>
        <ra-stack gap="8">
            <ra-input :label="$t('groups.affect.select') + '*'">
                <v-select
                    v-model="group_ids"
                    :items="groups"
                    hide-details
                    item-title="name"
                    item-value="id"
                    placeholder="Groupes"
                    variant="outlined"
                    multiple
                >
                    <template #selection="{ item }"
                        ><v-chip :color="item.raw.color">
                            <ra-typo color="black">{{ item.raw.name }}</ra-typo>
                        </v-chip></template
                    >
                    <template #item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title=""
                            style="display: flex"
                        >
                            <ra-stack
                                direction="horizontal"
                                alignment="center"
                            >
                                <v-checkbox-btn
                                    class="mr-2"
                                    style="flex-shrink: 0; margin-right: 8px"
                                    :model-value="
                                        group_ids.includes(item.raw.id)
                                    "
                                />
                                <v-chip :color="item.raw.color">
                                    <ra-typo color="black">{{
                                        item.raw.name
                                    }}</ra-typo>
                                </v-chip>
                            </ra-stack>
                        </v-list-item>
                    </template>
                </v-select>
            </ra-input>

            <p
                v-if="groups.length === 0"
                class="blue-text ml-2 mt-2"
            >
                {{ $t('groups.affect.no_ref') }}.
            </p>

            <ra-stack direction="horizontal">
                <ra-typo
                    no-word-wrap
                    small
                    >* {{ $t('generics.need_input') }}</ra-typo
                >
                <ra-spacer />
                <ra-btn
                    :disabled="group_ids === undefined"
                    :loading="loading"
                    bg="primary-gradient"
                    class="button primary block"
                    type="submit"
                    @click="affect()"
                >
                    {{ $t('groups.affect.cta') }}
                </ra-btn>
            </ra-stack>
        </ra-stack>
    </ra-base-dialog>
</template>

<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    export default {
        mixins: [mixins.RaDialogMixin],
        props: ['value', 'groups', 'affectedGroups'],
        data() {
            return {
                group_ids: [],
                loading: false,
            }
        },
        mounted() {
            this.group_ids = this.affectedGroups.map((group) => group.id)
        },
        methods: {
            affect() {
                this.$emit('onGroupSelected', this.group_ids, 'groupIds')
                this.close()
            },
        },
    }
</script>
