<template>
    <StartFormationLayout
        :close-drawer="isTablet"
        :current-step="currentStep"
        @back="handleBack"
    >
        <template #asideTitle>
            <tablet>
                <ra-box padding=",6">
                    <ra-stack
                        alignement="center"
                        direction="horizontal"
                        gap="5"
                    >
                        <b class="chip bg-primary">1</b>
                        <div gap="0">
                            <ra-typo variant="title">
                                {{
                                    $t(
                                        'headsets.pilotage.steps.step0.title.content3'
                                    )
                                }}
                                <ra-typo
                                    bold
                                    variant="title"
                                >
                                    {{
                                        $t(
                                            'headsets.pilotage.steps.step0.title.content3_huge'
                                        )
                                    }}
                                </ra-typo>
                            </ra-typo>
                        </div>
                    </ra-stack>
                </ra-box>
            </tablet>
        </template>

        <template #aside>
            <ra-box>
                <web>
                    <ra-typo
                        bold
                        with-track
                        margin="0,0,4,0"
                    >
                        {{
                            $t(
                                'headsets.pilotage.steps.start_session.select_headsets'
                            )
                        }}
                    </ra-typo>
                </web>
                <ra-stack
                    gap="4"
                    bg="white"
                    padding="5,4"
                    rounded
                >
                    <start-formation-headset-list
                        :headsets-app-driven="headsetsAppDriven"
                    />

                    <start-formation-add-headset-dialog-opener />
                </ra-stack>
            </ra-box>
        </template>

        <ra-stack>
            <select-formation
                v-if="!selectedFormation"
                :formations="translatedFormationsReadyForDrive"
                :packs="translatedPacksReadyForDrive"
                @click:formation="
                    (formation) => (selectedFormation = formation)
                "
            />
            <select-module
                v-else
                v-model:modules-selected="selectedModule"
                :formation="selectedFormation"
                :settings="settings"
                @start-formation="startFormation"
                @go-back="selectedFormation = null"
            />
        </ra-stack>
    </StartFormationLayout>
</template>

<script>
    import SelectFormation from '@components/Headsets/StartFormation/SelectFormation.vue'

    import { mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'
    import SelectModule from '@components/Headsets/StartFormation/SelectModule.vue'
    import { useAppStore } from '@store/app.js'
    import StartFormationHeadsetList from '@components/Headsets/StartFormation/StartFormationHeadsetList.vue'
    import StartFormationLayout from '@layouts/MDM/StartFormationLayout.vue'
    import { translateRecursively } from '@utils/translateRecursively.js'
    import { useLanguageStore } from '@store/lang.js'
    import FormationCollection from '@libs/Collections/Formation.js'
    import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'
    import StartFormationAddHeadsetDialogOpener from '@components/Headsets/StartFormation/StartFormationAddHeadsetDialogOpener.vue'
    import { HEADSET_TAGS } from '@libs/MDM/core/Headset.js'
    import {
        FormationHeadsetSessionSettings,
        HEADSET_FORMATIONS_SETTINGS_TEMPLATE,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    export default {
        name: 'StartSession',
        components: {
            StartFormationAddHeadsetDialogOpener,
            StartFormationLayout,
            StartFormationHeadsetList,
            SelectModule,
            SelectFormation,
        },

        data() {
            return {
                selectedFormation: null,
                selectedModule: [],
                settings: new FormationHeadsetSessionSettings(
                    HEADSET_FORMATIONS_SETTINGS_TEMPLATE,
                    undefined,
                    'temp'
                ),
                settingsValidated: false,
            }
        },
        computed: {
            ...mapState(useMDMStore, [
                'headsetsAppDriven',
                'formationReadyForDrive',
            ]),
            ...mapState(useLanguageStore, ['currentLocale']),
            ...mapState(useAppStore, ['isTablet']),
            translatedFormationsReadyForDrive() {
                return new FormationCollection(
                    [...this.formationReadyForDrive].map((f) =>
                        translateRecursively(f, this.currentLocale)
                    )
                )
            },
            translatedPacksReadyForDrive() {
                return this.translatedFormationsReadyForDrive.packs
            },
            currentStep() {
                let res = this.selectedFormation ? 2 : 1
                return window.IS_TABLET ? res : res - 1
            },
        },

        mounted() {
            if (this.headsetsAppDriven.length === 0) {
                this.goBack()
            }

            if ('formation_id' in this.$route.query) {
                this.selectedFormation =
                    this.translatedFormationsReadyForDrive.findById(
                        this.$route.query.formation_id
                    )
            }

            this.headsetsAppDriven.forEach((headset) => {
                headset.addTag(HEADSET_TAGS.SET_FORMATION)
            })
        },

        methods: {
            handleBack() {
                if (this.selectedFormation) {
                    this.selectedFormation = null
                    this.selectedModule = []
                    this.settings = new FormationHeadsetSessionSettings(
                        HEADSET_FORMATIONS_SETTINGS_TEMPLATE,
                        null,
                        'temp'
                    )
                } else {
                    this.goBack()
                }
            },
            goBack() {
                if (this.$route.query?.back) {
                    this.$router.push(this.$route.query.back)
                } else {
                    this.$router.push('/my-headsets')
                }
            },
            startFormation() {
                if (!this.selectedFormation) return
                for (let headset of this.headsetsAppDriven.filter((h) =>
                    h.hasTag(HEADSET_TAGS.SET_FORMATION)
                )) {
                    headset.WSsend({
                        type: 'startFormation',
                        modules_ids: this.selectedModule.length
                            ? this.selectedModule.map((m) => m.id)
                            : null,
                        settings: this.settings.isValidated
                            ? this.settings.toJSON()
                            : [],
                        formation_id: this.selectedFormation.id,
                    })
                }

                this.$router.push({
                    name: HeadsetRoutesNames.drive,
                    query: this.$route.query,
                })
            },
        },
    }
</script>
