<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { Headset } from '@libs/MDM/core/Headset.js'
    import {
        HeadsetSettingSession,
        RENAME_HEADSET_SETTING_TEMPLATE,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    /* eslint-disable vue/no-mutating-props */
    export default {
        mixins: [mixins.RaDialogMixin],
        name: 'HeadsetRenameDialog',
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },
        data() {
            return {
                settings: new HeadsetSettingSession(
                    RENAME_HEADSET_SETTING_TEMPLATE,
                    this.headset.entries
                ),
            }
        },

        methods: {
            async handleSubmit() {
                this.settings.apply(this.headset)
                this.close()
                setTimeout(() => {
                    console.log(this.headset)
                }, 500)
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $tc('headsets.actions.rename', 2) }}
        </template>
        <ra-stack>
            <ra-input>
                <v-text-field
                    v-model="settings.customName"
                    :label="$t('headsets.settings.headset_name_section')"
                    variant="outlined"
                />
            </ra-input>
            <ra-stack direction="horizontal">
                <ra-spacer />
                <v-btn
                    color="primary-gradient"
                    @click="handleSubmit"
                >
                    {{ $tc('headsets.actions.rename', 1) }}
                </v-btn>
            </ra-stack>
        </ra-stack>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
