<template>
    <div class="d-flex align-center justify-center text-center">
        <canvas id="chart" ref="chart" height="50"></canvas>
    </div>
</template>
<script>
import { Chart } from "chart.js";
export default {
    name: "ChartEvaluationRepartition",
    props: ["evaluation"],
    computed: {
        ctx() {
            return this.$refs.chart.getContext("2d");
        },
        labels() {
            //get label from the key of the object sessions_done
            return this.evaluation.map((e) => e.label);
        },
        dataset() {
            return {
                success: this.evaluation.map((e) => Math.round(e.correct_rate)),
                incorrect: this.evaluation.map((e) =>
                    Math.round(e.incorrect_rate)
                ),
            };
        },
        chartData() {
            const data = {
                labels: this.labels,
                datasets: [
                    {
                        type: "bar",
                        label: this.$t("session_stats.quiz_analysis.x_axe_1"),
                        data: this.dataset.success,
                        backgroundColor: "#3be23b",
                        yAxisID: "y1",
                    },
                    {
                        type: "bar",
                        label: this.$t("session_stats.quiz_analysis.x_axe_2"),
                        data: this.dataset.incorrect,
                        backgroundColor: "#ff0000",
                        yAxisID: "y1",
                    },
                ],
            };
            return data;
        },
        chartConfig() {
            return {
                type: "bar",
                data: this.chartData,
                options: {
                    legend: {
                        display: false,
                    },
                    scales: {
                        y1: {
                          type: "linear",
                          title: {
                            display: true,
                            text: this.$t(
                                "session_stats.quiz_analysis.y_axe"
                            ),
                          },
                          grid: {
                            display: false,
                          },
                          position: "right",
                          beginAtZero: true,
                          max: 100,
                          stepSize: 20,
                        },
                    },
                },
            };
        },
    },
    mounted() {
        this.chart = new Chart(this.ctx, this.chartConfig);
    },
    watch: {
        sessions_done() {
            this.$nextTick(() => {
                let ctx = this.ctx;
                let chart = new Chart(ctx, this.chartConfig);
            });
        },
        currentLocale() {
            this.chart.destroy();
            this.chart = new Chart(this.ctx, this.chartConfig);
        },
    },
};
</script>
<style lang=""></style>
