<script>
export default {
  props:{
    module:{
      type : Object,
      required: true
    },
    selectMode:{
      type: Boolean,
      default: false
    },
    selected:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <ra-stack direction="horizontal" alignement="center" class="start-formation-module-card" rounded>
    <ra-box v-if="selectMode"  class="module-selection-checkbox" bg="white">
      <ra-icon  :color="selected ? 'primary' : 'primary-lighten-9'">
        {{selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
      </ra-icon>
    </ra-box>
    <ra-image :src="module?.image?.url + '?width=80'" height="100%" width="80px" fallback="/images/placeholder.webp"/>
    <ra-box>
      <ra-typo semi>{{module.name}}</ra-typo>
      <ra-stack direction="horizontal">
        <ra-typo>Module n°{{module.number}}</ra-typo>
        <ra-typo>
          <ra-icon style="font-weight: 100">
            mdi-clock
          </ra-icon>
          {{module.nb_minutes}} min
        </ra-typo>
      </ra-stack>
    </ra-box>
  </ra-stack>
</template>

<style scoped lang="scss">
.start-formation-module-card{
  &:hover{
    background: var(--primary-lighten-9);
    cursor: pointer;
  }
  border: 1px var(--primary-lighten-9) solid;
  position:relative;
  .module-selection-checkbox{
    i{
      position: absolute;
      transform:  scale(1.8) translateX(1px) translateY(1px);
    }
    width: 20px;
    height: 20px;
    position: absolute;
    left: 40px;
    top: 40px;
    transform: translate(-50%, -50%);
  }
}
</style>