import { CompaniesRoutesName } from '@core/router/routes/God/Companies.names.js'
import { ContentRoutesNames } from '@core/router/routes/God/Content.names.js'
import { HeadsetRoutesNames } from '@core/router/routes/generators/Headset.names.js'
import { ResourcesRoutesName } from '@core/router/routes/Authentificated/Resources.names.js'
import { ApplicationRoutesNames } from '@core/router/routes/God/Application.names.js'
import { MyFormationsRoutesName } from '@core/router/routes/Authentificated/MyFormations.names.js'
import { AuthentificatedRoutesName } from '@core/router/routes/Authentificated/index.js'
import { MyStatRoutesName } from '@core/router/routes/Former/MyStats.names.js'
import { MyAccountRoutesName } from '@core/router/routes/Authentificated/MyAccount.names.js'
import { WorkshopRoutesNames } from '@core/router/routes/generators/Workshop.names.js'
import { MyUsersRoutesNames } from '@routes/Former/MyUsers.name.js'
import { DevRoutesNames } from '@routes/God/Dev.names.js'
import { reactive } from 'vue'

export class NavigationDrawerContent {
    static godAndSuperGod = [
        {
            routeName: CompaniesRoutesName.index,
            i18nKey: 'global.navigation.companies',
            icon: 'mdi-domain',
        },
        {
            routeName: ContentRoutesNames.content,
            i18nKey: 'global.navigation.content',
            icon: 'fa-graduation-cap',
        },

        {
            routeName: WorkshopRoutesNames.index,
            i18nKey: 'global.navigation.my_workshops',
            icon: 'fa-users-class',
            env: 'vr',
        },
        {
            routeName: HeadsetRoutesNames.index,
            i18nKey: 'global.navigation.my_headsets',
            icon: 'fa-vr-cardboard',
            env: 'vr',
        },
        {
            routeName: MyStatRoutesName.index,
            i18nKey: 'global.navigation.my_stats',
            icon: 'mdi-chart-bar',
        },
        {
            routeName: ResourcesRoutesName.index,
            i18nKey: 'global.navigation.resources',
            icon: 'fa-books',
        },
        {
            routeName: ApplicationRoutesNames.index,
            i18nKey: 'global.navigation.application',
            icon: 'mdi-application',
            env: 'vr',
        },
    ]
    static navigationByRoles = {
        god: NavigationDrawerContent.godAndSuperGod,
        super_god: NavigationDrawerContent.godAndSuperGod,
        admin: [
            {
                routeName: AuthentificatedRoutesName.home,
                i18nKey: 'global.navigation.home',
                icon: 'fa-home',
            },
            {
                routeName: MyFormationsRoutesName.index,
                i18nKey: 'global.navigation.my_formations',
                icon: 'fa-graduation-cap',
            },
            {
                routeName: MyUsersRoutesNames.formers,
                i18nKey: 'global.navigation.my_users',
                icon: 'fa-users',
                activeIf($route) {
                    return (
                        $route.path.startsWith('/companies') ||
                        $route.path.startsWith('/' + MyUsersRoutesNames.index)
                    )
                },
            },
            {
                routeName: WorkshopRoutesNames.index,
                i18nKey: 'global.navigation.my_workshops',
                icon: 'fa-users-class',
                env: 'vr',
            },

            {
                routeName: HeadsetRoutesNames.index,
                i18nKey: 'global.navigation.my_headsets',
                icon: 'fa-vr-cardboard',
                env: 'vr',
            },
            {
                routeName: MyStatRoutesName.index,
                i18nKey: 'global.navigation.my_stats',
                icon: 'mdi-chart-bar',
            },

            {
                routeName: ResourcesRoutesName.index,
                i18nKey: 'global.navigation.resources',
                icon: 'fa-books',
            },
        ],
        former: [
            {
                routeName: AuthentificatedRoutesName.home,
                i18nKey: 'global.navigation.home',
                icon: 'fa-home',
            },
            {
                routeName: MyFormationsRoutesName.index,
                i18nKey: 'global.navigation.my_formations',
                icon: 'fa-graduation-cap',
            },
            {
                routeName: MyUsersRoutesNames.learners,
                i18nKey: 'global.navigation.my_learners',
                icon: 'fa-users',
                activeIf($route) {
                    return (
                        $route.path.startsWith('/companies') ||
                        $route.path.startsWith('/' + MyUsersRoutesNames.index)
                    )
                },
            },
            {
                routeName: WorkshopRoutesNames.index,
                i18nKey: 'global.navigation.my_workshops',
                icon: 'fa-users-class',
                env: 'vr',
            },
            {
                routeName: HeadsetRoutesNames.index,
                i18nKey: 'global.navigation.my_headsets',
                icon: 'fa-vr-cardboard',
                env: 'vr',
            },
            {
                routeName: MyStatRoutesName.index,
                i18nKey: 'global.navigation.my_stats',
                icon: 'mdi-chart-bar',
            },

            {
                routeName: ResourcesRoutesName.index,
                i18nKey: 'global.navigation.resources',
                icon: 'fa-books',
            },
        ],
        learner: [
            {
                routeName: MyFormationsRoutesName.index,
                i18nKey: 'global.navigation.my_formations',
                icon: 'fa-graduation-cap',
            },
            {
                routeName: ResourcesRoutesName.faqs.index,
                i18nKey: 'global.navigation.resources',
                icon: 'fa-books',
            },
            {
                routeName: MyAccountRoutesName.profile,
                i18nKey: 'global.navigation.my_account',
                icon: 'mdi-account',
            },
        ],
    }

    static navigationDevelopers = [
        {
            routeName: DevRoutesNames.distributor.index,
            i18nKey: 'global.navigation.distributors',
            icon: 'fa-users',
        },
        {
            routeName: DevRoutesNames.websocket.index,
            i18nKey: 'global.navigation.websocket',
            icon: 'mdi-network',
        },
    ]
    constructor(user, envMode = 'vr', router) {
        this.user = user
        this.envMode = envMode
        this.router = router
        return reactive(this)
    }

    get isDev() {
        return (
            this.router.currentRoute.path.startsWith('/dev') && this.user?.isGod
        )
    }

    get role() {
        return this?.user?.role
    }

    /**
     * @returns {Array}
     */
    get content() {
        if (this.isDev) return NavigationDrawerContent.navigationDevelopers
        if (this.role) return this.constructor.navigationByRoles[this.role.name]
        else []
    }

    getLinks() {
        return this.content
            .map(
                ({
                    routeName,
                    i18nKey,
                    icon = 'mdi-help',
                    env = null,
                    activeIf = null,
                }) => {
                    if (env != null) {
                        if (env != this.envMode) return null
                    }
                    let route = this.router.resolve({ name: routeName })
                    return {
                        label: i18nKey,
                        icon,
                        url: route.href,
                        activeIf,
                    }
                }
            )
            .filter(Boolean)
    }
}
