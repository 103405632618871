import ApiClient from '@api'

export class FormationsProvider {
    static api = ApiClient.getInstance()

    static route = '/formations'
    static userRoute = '/user/formations'
    static getFormations(query = {}) {
        return this.api.get(`${this.route}`, query)
    }

    static getUserFormations(query = {}) {
        return this.api.get(`${this.userRoute}`, query)
    }

    static getFormation(id, query = {}, ...args) {
        return this.api.get(`${this.route}/${id}`, query, ...args)
    }

    static createFormation(payload, query = {}) {
        return this.api.post(`${this.route}`, payload, query)
    }

    static updateFormation(id, payload, query = {}) {
        return this.api.put(`${this.route}/${id}`, payload, query)
    }

    static deleteFormation(id, query = {}) {
        return this.api.delete(`${this.route}/${id}`, query)
    }

    static duplicateFormation(id, query = {}) {
        return this.api.post(`${this.route}/${id}/duplicate`, {}, query)
    }

    static putInLive(id, query = {}) {
        return this.api.post(`${this.route}/${id}/live`, {}, query)
    }

    /**
     * Sharing providers
     */

    static generateShareLink(payload, query = {}) {
        return this.api.post(`/share-formation`, payload, query)
    }

    static sendShareEmail(payload, query = {}) {
        return this.api.post(`/share-formation/send`, payload, query)
    }

    static requestForCredit(formation_id, type) {
        return this.api.post('/ask-formation-access', {
            formation_id,
            type,
        })
    }

    static getFormationStatsBySession(id, query = {}) {
        return this.api.get(`${this.route}/${id}/sessions/stats`, query)
    }

    /**
     * Used by scorm remote
     */
    static hasExternalAccessFormation(formationId) {
        return this.api
            .get(`${this.userRoute}/${formationId}/have-external-access`)
            .doNotCatch()
    }
}
