<script>
    import { Headset } from '@libs/MDM/core/Headset.js'
    import StreamPlayerControls from '@components/Headsets/Pilotage/Players/StreamPlayerControls.vue'
    import HeadsetStatusConnecting from '@components/Headsets/Pilotage/Players/StatusViews/HeadsetStatusConnecting.vue'
    import HeadsetStatusIdle from '@components/Headsets/Pilotage/Players/StatusViews/HeadsetStatusIdle.vue'
    import HeadsetStatusNotReachable from '@components/Headsets/Pilotage/Players/StatusViews/HeadsetStatusNotReachable.vue'
    import CurrentModuleStreamPlayer from '@components/Headsets/Pilotage/Players/CurrentModuleStreamPlayer.vue'

    export default {
        components: {
            CurrentModuleStreamPlayer,
            HeadsetStatusNotReachable,
            HeadsetStatusIdle,
            HeadsetStatusConnecting,
            StreamPlayerControls,
        },
        props: {
            headset: {
                type: Headset,
                default: undefined,
            },
            compact: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['togglePinned'],

        computed: {
            opacity() {
                return this?.headset?.isIdle ? 0 : 1
            },
        },

        watch: {
            headset: {
                handler(newHeadset, oldHeadset) {
                    this.detach(oldHeadset)
                    this.attach(newHeadset)
                },
            },
        },
        mounted() {
            this.attach(this.headset)
        },

        unmounted() {
            this.detach(this.headset)
        },

        methods: {
            attach(headset) {
                if (!headset) return
                if (!headset?.stream) return
                headset.stream.attach(this, this.$refs['video-container'])
            },
            detach(headset) {
                if (!headset) return
                if (!headset?.stream) return
                headset.stream.detach(this, this.$refs['video-container'])
            },
        },
    }
</script>

<template>
    <ra-box
        bg="grey-700"
        rounded
        class="stream-player-video"
        height="100%"
    >
        <ra-typo
            v-if="headset"
            class="stream-player-video--headset-name"
            >{{ headset.name }}</ra-typo
        >
        <span
            ref="video-container"
            class="video-container"
            :style="{ opacity }"
        />
        <template v-if="headset">
            <headset-status-connecting
                :compact="compact"
                :headset="headset"
            />
            <headset-status-idle
                :compact="compact"
                :headset="headset"
            />
            <headset-status-not-reachable
                :compact="compact"
                :headset="headset"
            />
            <stream-player-controls
                v-if="headset.isConnected"
                :stream="headset.stream"
                class="controls"
                @toggle-pinned="$emit('togglePinned')"
            />
            <current-module-stream-player
                v-if="!compact"
                :stream="headset.stream"
            />
        </template>
    </ra-box>
</template>

<style lang="scss">
    .headset-status-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(9, 9, 84, 0.5);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<style lang="scss">
    .stream-player-video {
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            background-image: linear-gradient(
                rgba(9, 9, 84, 0.3),
                rgba(9, 9, 84, 0)
            );
            top: 0;
            left: 0;
            right: 0;
            height: 50px;
        }

        .controls {
            position: absolute;
            opacity: 0;
            bottom: 1em;
            transform: translateY(0.5em);
            z-index: 1;
            transition: 0.2s;
        }
        &:hover .controls {
            opacity: 1;
            transform: translateY(0);
        }

        .video-container {
            transition: 0.1s;
        }

        video {
            width: 100%;
            aspect-ratio: 16/9;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
        }

        &--headset-name {
            position: absolute;
            top: 1em;
            left: 1em;
            z-index: 1;
        }
    }
</style>
