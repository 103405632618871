<script>
    export default {
        props: {
            tooltip: {
                type: String,
                default: null,
            },
            tooltipOptions: {
                type: Object,
                default: () => ({}),
            },
        },
    }
</script>

<template>
    <ra-typo :tooltip-options="tooltipOptions">
        <ra-icon
            v-web
            fill
            class="help"
        >
            fa-info
        </ra-icon>
        <ra-icon
            v-tablet
            fill
            class="help"
            size="20"
        >
            fa-info
        </ra-icon>
        <template #tooltip>
            <slot>{{ tooltip }}</slot>
        </template>
    </ra-typo>
</template>

<style scoped lang="scss">
    .help {
        color: var(--primary-60-alpha);
        cursor: pointer;
        :hover {
            color: var(--primary-90-alpha);
        }
    }
</style>
