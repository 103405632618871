<script>
    import {
        HEADSET_SETTINGS_TEMPLATE,
        HeadsetSettingSession,
        RENAME_HEADSET_SETTING_TEMPLATE,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession'
    import HeadsetSettingItem from './HeadsetSettingItem.vue'
    import HeadsetSettingTitle from './HeadsetSettingTitle.vue'
    import HeadsetSettingInput from './HeadsetSettingInput.vue'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers'
    import LaunchModeHeadsetSetting from './LaunchModeHeadsetSetting.vue'
    import { mapActions } from 'pinia'
    import { useMDMStore } from '@/store/mdm'
    import { useGlobalDialog } from '@/store/globalDialog'
    import { useGlobalSnackBar } from '@/store/globalSnakbar'
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    export default {
        components: {
            HeadsetSettingTitle,
            HeadsetSettingItem,
            LaunchModeHeadsetSetting,
            HeadsetSettingInput,
        },
        mixins: [mixins.RaAlertMixin],
        props: {
            headsets: {
                type: Array,
                required: true,
            },
        },
        emits: ['close'],
        data() {
            return {
                currentTab: 0,
                settings: new HeadsetSettingSession(HEADSET_SETTINGS_TEMPLATE),
                customNameSetting: new HeadsetSettingSession(
                    RENAME_HEADSET_SETTING_TEMPLATE
                ),
                editName: false,
            }
        },
        computed: {
            /**
             * @returns {Headset}
             */
            headset() {
                return this.headsets.length === 1 && this.headsets[0]
            },
            STATE_ENTRY_IDENTIFIERS() {
                return STATE_ENTRY_IDENTIFIERS
            },
            tabs() {
                return [
                    {
                        name: this.$t(
                            'headsets.settings.tabs.headset_settings'
                        ),
                        component: 'HeadsetSettings',
                    },
                    {
                        name: this.$t(
                            'headsets.settings.tabs.formations_settings'
                        ),
                        component: 'HeadsetSecurity',
                    },
                ]
            },
        },
        mounted() {
            if (this.headset) {
                this.settings = new HeadsetSettingSession(
                    HEADSET_SETTINGS_TEMPLATE,
                    this.headset.entries
                )

                this.customNameSetting = new HeadsetSettingSession(
                    RENAME_HEADSET_SETTING_TEMPLATE,
                    this.headset.entries
                )
            }
        },
        methods: {
            ...mapActions(useMDMStore, ['unSyncAnHeadset']),
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            ...mapActions(useGlobalSnackBar, ['showSnackBar', 'hideSnackBar']),
            async handleSave() {
                for (let headset of this.headsets) this.settings.apply(headset)
                this.alert(this.$tc('headsets.alerts.saved', 1), 'success')
                this.$emit('close')
            },
            handleDelete() {
                this.setGlobalDialog({
                    visible: true,
                    title: this.$t('headsets.actions.delete._name'),
                    message: this.$t('headsets.actions.delete.validation', {
                        name: this.headset.name,
                    }),
                    positiveButton: {
                        title: this.$t('generics.yes'),
                        action: () => {
                            this.unSyncAnHeadset(this.headset)
                        },
                    },
                })
            },

            toogleEditName() {
                if (!this.headset)
                    return console.error(
                        'You cannot edit name of a group of headsets'
                    )
                this.editName = !this.editName
                if (this.editName === false) {
                    this.customNameSetting.apply(this.headset)
                } else {
                    this.$nextTick(() => {
                        this.$refs.customName.focus()
                    })
                }
            },
        },
    }
</script>

<template>
    <ra-box>
        <ra-alert
            ref="alert"
            global
        />
        <ra-stack
            width="auto"
            margin="8"
            padding="6"
            gap="4"
            bg="white"
            rounded
        >
            <ra-stack
                v-if="headset"
                direction="horizontal"
                distribution="fill-equally"
                gap="6"
            >
                <ra-box
                    bg="grey-50"
                    padding="4"
                    rounded
                >
                    <ra-typo bold>{{
                        $t('headsets.settings.headset_name_section')
                    }}</ra-typo>
                    <ra-spacer height="16px" />
                    <ra-stack
                        direction="horizontal"
                        alignement="center"
                        gap="3"
                    >
                        <ra-box width="64px">
                            <img
                                :src="headset.image"
                                alt="headset"
                                width="64"
                            />
                        </ra-box>
                        <ra-stack
                            :class="[editName ? 'edit-name' : '']"
                            direction="horizontal"
                            alignement="center"
                            flex="1"
                            bg="white"
                            rounded
                            padding="3,4"
                        >
                            <ra-typo
                                semi
                                flex="1"
                            >
                                <input
                                    v-if="editName"
                                    ref="customName"
                                    v-model="
                                        customNameSetting[
                                            STATE_ENTRY_IDENTIFIERS.CUSTOM_NAME
                                        ]
                                    "
                                    type="text"
                                    class="text-primary-lighten-5"
                                    :placeholder="
                                        $t(
                                            'headsets.settings.headset_name_section'
                                        )
                                    "
                                    @keypress.enter="toogleEditName"
                                />
                                <span v-else>
                                    {{ headset.name }}
                                </span>
                            </ra-typo>
                            <v-btn
                                icon
                                variant="plain"
                                density="compact"
                                @click="toogleEditName"
                            >
                                <ra-icon
                                    v-if="!editName"
                                    fill
                                >
                                    fa-edit
                                </ra-icon>
                                <ra-icon
                                    v-else
                                    fill
                                >
                                    fa-check
                                </ra-icon>
                            </v-btn>
                        </ra-stack>
                    </ra-stack>
                </ra-box>
                <ra-box
                    bg="grey-50"
                    padding="4"
                    rounded
                >
                    <ra-typo bold>{{
                        $t(
                            'headsets.settings.headset_application_release.label'
                        )
                    }}</ra-typo>
                    <ra-spacer height="16px" />
                    <ra-stack
                        direction="horizontal"
                        gap="3"
                    >
                        <ra-box
                            width="48px"
                            height="48px"
                        >
                            <img
                                src="@assets/images/logos/RA_placeholder.png"
                                alt="headset"
                                width="48"
                                height="48"
                            />
                        </ra-box>
                        <ra-box>
                            <ra-typo variant="small">
                                <ra-typo semi>
                                    {{
                                        headset.entries[
                                            STATE_ENTRY_IDENTIFIERS.RELEASE_NAME
                                        ]
                                    }}
                                    Version
                                    {{
                                        headset.entries[
                                            STATE_ENTRY_IDENTIFIERS
                                                .RELEASE_VERSION
                                        ]
                                    }}
                                </ra-typo>
                            </ra-typo>
                            <ra-typo variant="small">
                                <ra-typo
                                    semi
                                    span
                                >
                                    {{
                                        $t(
                                            'headsets.settings.headset_application_release.size'
                                        )
                                    }}
                                </ra-typo>
                                {{
                                    headset.entries[
                                        STATE_ENTRY_IDENTIFIERS.RELEASE_SIZE
                                    ] ?? 'N/A'
                                }}
                            </ra-typo>
                            <ra-typo variant="small">
                                <ra-typo
                                    semi
                                    span
                                >
                                    {{
                                        $t(
                                            'headsets.settings.headset_application_release.last_update'
                                        )
                                    }}
                                </ra-typo>
                                {{
                                    headset.entries[
                                        STATE_ENTRY_IDENTIFIERS.RELEASE_DATE
                                    ] ?? 'N/A'
                                }}</ra-typo
                            >
                        </ra-box>
                    </ra-stack>
                </ra-box>
            </ra-stack>

            <ra-box>
                <ra-tabs
                    v-model="currentTab"
                    :tabs="tabs"
                    no-router
                ></ra-tabs>

                <template v-if="currentTab === 0">
                    <ra-stack
                        direction="horizontal"
                        gap="6"
                    >
                        <!-- Left Col -->
                        <ra-stack gap="2">
                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_user_settings.interaction.label'
                                        )
                                    }}
                                </headset-setting-title>
                                <headset-setting-input>
                                    <ra-tab-select
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS
                                                    .INTERACTION_MODE
                                            ]
                                        "
                                        :options="[
                                            {
                                                label: $t(
                                                    'headsets.settings.headset_user_settings.interaction.values.pointer'
                                                ),
                                                icon: 'fa-eye',
                                                value: 0,
                                                iconOptions: {
                                                    size: 16,
                                                    fill: true,
                                                },
                                            },
                                            {
                                                label: $t(
                                                    'headsets.settings.headset_user_settings.interaction.values.controller'
                                                ),
                                                icon: 'fa-controller',
                                                value: 1,
                                                iconOptions: {
                                                    size: 16,
                                                    fill: true,
                                                },
                                            },
                                        ]"
                                        tablet-display
                                    />
                                </headset-setting-input>
                            </headset-setting-item>
                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_user_settings.controller.label'
                                        )
                                    }}
                                </headset-setting-title>
                                <headset-setting-input>
                                    <ra-tab-select
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS.HANDNESS
                                            ]
                                        "
                                        :options="[
                                            {
                                                label: 'Gauche',
                                                icon: 'fa-hand-left',
                                                value: 0,
                                                iconOptions: {
                                                    size: 18,
                                                    fill: true,
                                                },
                                            },
                                            {
                                                label: 'Droite',
                                                icon: 'fa-hand-right',
                                                value: 1,
                                                iconOptions: {
                                                    size: 18,
                                                    fill: true,
                                                },
                                            },
                                        ]"
                                        tablet-display
                                    />
                                </headset-setting-input>
                            </headset-setting-item>
                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_application.lang.label'
                                        )
                                    }}
                                </headset-setting-title>
                                <headset-setting-input>
                                    <ra-box width="120px">
                                        <ra-tab-select
                                            v-model="
                                                settings[
                                                    STATE_ENTRY_IDENTIFIERS
                                                        .LANGUAGE
                                                ]
                                            "
                                            :options="[
                                                {
                                                    label: 'FR',
                                                    value: 'fr',
                                                },
                                                {
                                                    label: 'EN',
                                                    value: 'en',
                                                },
                                            ]"
                                            tablet-display
                                        />
                                    </ra-box>
                                </headset-setting-input>
                            </headset-setting-item>
                        </ra-stack>
                        <!-- RightCol -->
                        <ra-stack>
                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_auto_update.title'
                                        )
                                    }}
                                    <template #help>
                                        <p
                                            v-html="
                                                $t(
                                                    'headsets.settings.headset_auto_update.tooltip'
                                                )
                                            "
                                        />
                                    </template>
                                </headset-setting-title>
                                <headset-setting-input>
                                    <v-switch
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS
                                                    .AUTO_UPDATE_FORMATION
                                            ]
                                        "
                                        color="primary"
                                        density="compact"
                                        hide-details
                                        :label="
                                            $t(
                                                'headsets.settings.headset_auto_update.label_formations'
                                            )
                                        "
                                    />
                                </headset-setting-input>
                                <headset-setting-input>
                                    <v-switch
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS
                                                    .AUTO_UPDATE_APP
                                            ]
                                        "
                                        color="primary"
                                        density="compact"
                                        hide-details
                                        :label="
                                            $t(
                                                'headsets.settings.headset_auto_update.label_app'
                                            )
                                        "
                                    />
                                </headset-setting-input>
                            </headset-setting-item>
                        </ra-stack>
                    </ra-stack>
                </template>
                <template v-if="currentTab === 1">
                    <ra-stack
                        direction="horizontal"
                        gap="6"
                    >
                        <launch-mode-headset-setting
                            v-model="
                                settings[
                                    STATE_ENTRY_IDENTIFIERS.LAUNCH_TRAINING
                                ]
                            "
                        />

                        <ra-box flex="0 0 30%">
                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_formation_settings.choice_module.title'
                                        )
                                    }}
                                </headset-setting-title>
                                <headset-setting-input>
                                    <v-switch
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS
                                                    .LEANER_CAN_CHOOSE_MODULE
                                            ]
                                        "
                                        :label="
                                            $t(
                                                'headsets.settings.headset_formation_settings.choice_module.label'
                                            )
                                        "
                                        color="primary"
                                        hide-details
                                    />
                                </headset-setting-input>
                            </headset-setting-item>

                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_formation_settings.quiz.label'
                                        )
                                    }}
                                </headset-setting-title>
                                <headset-setting-input
                                    :setting-key="
                                        STATE_ENTRY_IDENTIFIERS.QUIZ_VR
                                    "
                                >
                                    <v-switch
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS.QUIZ_VR
                                            ]
                                        "
                                        :label="
                                            $t(
                                                'headsets.settings.headset_formation_settings.quiz.vr_quiz'
                                            )
                                        "
                                        color="primary"
                                        hide-details
                                    />
                                </headset-setting-input>
                                <headset-setting-input
                                    :setting-key="
                                        STATE_ENTRY_IDENTIFIERS.QUIZ_EL
                                    "
                                >
                                    <v-switch
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS.QUIZ_EL
                                            ]
                                        "
                                        :label="
                                            $t(
                                                'headsets.settings.headset_formation_settings.quiz.el_quiz'
                                            )
                                        "
                                        color="primary"
                                        hide-details
                                    />
                                </headset-setting-input>
                            </headset-setting-item>
                            <headset-setting-item>
                                <headset-setting-title>
                                    {{
                                        $t(
                                            'headsets.settings.headset_synthesis_setting.title'
                                        )
                                    }}
                                </headset-setting-title>
                                <headset-setting-input>
                                    <v-switch
                                        v-model="
                                            settings[
                                                STATE_ENTRY_IDENTIFIERS
                                                    .SEND_SYNTHESIS_SHEET
                                            ]
                                        "
                                        :label="
                                            $tc(
                                                'headsets.settings.headset_synthesis_setting.label',
                                                2
                                            )
                                        "
                                        color="primary"
                                        hide-details
                                    />
                                </headset-setting-input>
                            </headset-setting-item>
                        </ra-box>
                    </ra-stack>
                </template>
            </ra-box>
            <ra-stack
                class="btn-stack"
                direction="horizontal"
                distribution="side-spacing"
                gap="6"
                mobile-swap
                wrap
            >
                <ra-btn
                    bg="primary-lighten-5"
                    semi
                    uppercase
                    width="230px"
                    @click="handleDelete()"
                >
                    {{ $t('headsets.actions.delete._name') }}
                </ra-btn>
                <ra-btn
                    bg="primary-gradient"
                    semi
                    uppercase
                    width="230px"
                    @click="handleSave"
                >
                    {{ $t('generics.save') }}
                </ra-btn>
            </ra-stack>
        </ra-stack>
    </ra-box>
</template>

<style lang="scss">
    .edit-name {
        box-shadow: 0 0 0 2px var(--primary-60-alpha);
        transition: 0.2s;
        input {
            outline: none;
        }
    }
</style>
