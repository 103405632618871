<script>
    import Callout from '@ui/Callout.vue'

    export default {
        components: { Callout },
        data() {
            return {
                edit: false,
                group: {
                    id: null,
                    name: 'Test',
                    users: [],
                },
            }
        },

        computed: {
            users() {
                let groups = ['Jane Doe', 'Jean dupont']
                return groups.join(', ')
            },
        },

        methods: {
            toggleEdit() {
                this.edit = !this.edit
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="4"
    >
        <ra-card>
            <template #title>
                {{ $tc('groups._name.label1', 2) }}
            </template>
            <template #right>
                <ra-icon
                    v-if="!edit"
                    weight="bold"
                    class="clickable"
                    @click="toggleEdit"
                    >fa-edit</ra-icon
                >
                <ra-typo
                    v-else
                    span
                    underline
                    class="clickable"
                    @click="toggleEdit"
                    >{{ $t('generics.cancel') }}</ra-typo
                >
            </template>
            <ra-stack padding="3">
                <template v-if="!edit">
                    <ra-typo>
                        <v-chip>Groupe</v-chip>
                    </ra-typo>
                    <ra-typo>
                        <ra-typo
                            span
                            semi
                            >{{ $t('groups.users') }}:</ra-typo
                        >
                        {{ users }}
                    </ra-typo>
                    <ra-typo>
                        <ra-typo
                            span
                            semi
                            >{{
                                $t('groups.users_connected_by_default')
                            }}:</ra-typo
                        >
                        {{ users }}
                    </ra-typo>
                </template>

                <template v-else>
                    <ra-input name="group_id">
                        <ra-auto-complete
                            v-model="group"
                            hide-details
                            density="comfortable"
                            variant="outlined"
                            item-title="name"
                        ></ra-auto-complete>
                    </ra-input>
                    <Callout bg="primary-5-alpha">{{
                        $t('groups.headset_group_callout')
                    }}</Callout>
                    <ra-stack direction="horizontal">
                        <ra-spacer />
                        <v-btn
                            variant="flat"
                            color="primary-gradient"
                        >
                            {{ $t('generics.save') }}
                        </v-btn>
                    </ra-stack>
                </template>
            </ra-stack>
        </ra-card>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
