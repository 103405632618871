import { reactive } from 'vue'
import { HeadsetStream } from './HeadsetStream'
import { RTCConnection, RTCConnectionStatus } from './RTCConnection'
import { RTCEvent } from './RaPeer'
import { Database } from '@core/database/init.js'
import { useGlobalSnackBar } from '@store/globalSnakbar.js'
import { HeadsetImage } from './HeadsetImage.js'
import { SyncedHeadsets } from '@core/database/tables/SyncedHeadsets.js'
import { EventEmmiter } from '@utils/EventEmmiter.js'
import i18n from '@core/i18n/index.js'
import './Headset.type.js'
import { HeadsetFormationsCollection } from '@libs/Collections/HeadsetFormationsCollection.js'
import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'
import { HeadsetStateEntries } from '@libs/MDM/core/Entries/HeadsetStateEntries.js'
export const HEADSET_ACTIONS = {
    PAUSE: 'pause',
    RESUME: 'resume',
    SOUND_MUTE: 'sound_mute',
    SOUND_UNMUTE: 'sound_unmute',
    MICRO_MUTE: 'micro_mute',
    MICRO_UNMUTE: 'micro_unmute',
    SCREEN_RESOLUTION: 'screen_resolution',
    START_STREAM: 'start_stream',
    STOP_STREAM: 'stop_stream',
}

export const HEADSET_EVENTS = {
    STATE: 'state',
    WS_CONNECTED: 'ws_connected',
    WS_DISCONNECTED: 'ws_disconnected',
}

export const HEADSET_TAGS = {
    DRIVEN: 'driven',
    SET_FORMATION: 'set_formation',
}

export class Headset extends EventEmmiter {
    static mdmStore = null /** @set in @store/mdm.js */
    static instances = new Map()

    constructor(serialNumber, config = null) {
        super()

        this.tags = new Set()

        this._ws = null

        /**
         * The serial number of the headset
         * @type {String}
         */
        this.serialNumber = serialNumber

        /**
         * @type {RTCConnection}
         */
        this.receivingRTCConnection = new RTCConnection(
            {
                initiator: false,
                trickle: false,
            },
            reactive(this)
        )
        /**
         * @type {RTCConnection}
         */
        this.emitingRTCConnection = new RTCConnection(
            {
                initiator: true,
                trickle: false,
            },
            reactive(this)
        )

        /**
         * @type {HeadsetStateEntries}
         */

        this.entries = new HeadsetStateEntries([])
        /**
         * @type {HeadsetState}
         */
        this.state = {}
        this.setState(
            // eslint-disable-next-line no-constant-binary-expression
            {
                ...config.state,
            } || {
                battery: null,
                name: 'Unknown',
            }
        )

        /**
         * Determine if a formation is currently running on this headset, and the modules informations
         * @type {null|Object}
         */
        this.currentFormation = reactive({})

        /**
         * The stream instance of the headset
         * @type {HeadsetStream}
         */
        let self = reactive(this)
        this.stream = HeadsetStream.getInstance(self)
        this.stream.headset = self

        /**
         * Define the headset instance in the instances map
         */
        Headset.instances.set(serialNumber, self)
        return self
    }

    set ws(ws) {
        this._ws = ws
        if (ws) this.emit(HEADSET_EVENTS.WS_CONNECTED)
        else this.emit(HEADSET_EVENTS.WS_DISCONNECTED)
    }

    /**
     * Get the last user connected to this headset
     * @returns {Object}
     */
    get user() {
        return (
            this.state?.last_session?.user || {
                identity: 'Unknown',
            }
        )
    }

    get id() {
        return this.state?.id || undefined
    }

    get name() {
        return (
            this.entries?.[STATE_ENTRY_IDENTIFIERS.CUSTOM_NAME] ||
            this.entries?.[STATE_ENTRY_IDENTIFIERS.NAME] ||
            'Unknown'
        )
    }

    get model() {
        return this.entries[STATE_ENTRY_IDENTIFIERS.MODEL]
    }

    get image() {
        return HeadsetImage.getImage(this.model)
    }
    /**
     * Get the status of the headset (connected to websocket == connected and ready to RTC)
     * @returns {boolean}
     */
    get isConnected() {
        return Boolean(this._ws)
    }

    get statusLabel() {
        if (!this.isConnected)
            return i18n.global.t(
                'headsets.properties.status.values.disconnected'
            )
        if (this.isIdle)
            return i18n.global.t('headsets.properties.status.values.idle')
        return i18n.global.t('headsets.properties.status.values.connected')
    }

    get statusColor() {
        if (!this.isConnected) return 'primary-lighten-7'
        if (this.isIdle) return 'warning'
        return 'green'
    }

    /**
     * Determine if the headset is an online or a local headset
     * @returns {*|boolean}
     * @private
     */
    get _isLAN() {
        return this._ws?.isLAN || false
    }
    /**
     * Determine if the headset has an opened RTCConnection
     * @returns {boolean}
     */
    get hasRTCConnection() {
        return (
            Boolean(!this.emitingRTCConnection.isClosed()) ||
            Boolean(!this.receivingRTCConnection.isClosed())
        )
    }

    get isRTCConnecting() {
        return (
            this.emitingRTCConnection.hasStatus([
                RTCConnectionStatus.CONNECTING,
            ]) ||
            this.receivingRTCConnection.hasStatus([
                RTCConnectionStatus.CONNECTING,
            ])
        )
    }

    get hasWSSocket() {
        return Boolean(this._ws)
    }

    get streamable() {
        return this.entries?.[STATE_ENTRY_IDENTIFIERS.STREAMABLE] ?? false
    }

    /**
     * Determine if the headset is drivable
     * A headset is drivable if it has an opened RTCConnection, not in idle state, not in stream on another device, and connected to the websocket
     * @returns {boolean|*|boolean}
     */
    get isDrivable() {
        if (this.hasRTCOpen) return true
        if (this.isIdle) return false
        return (this.isConnected && this.streamable) || false
    }

    /**
     * Does the headset has an opened RTCConnection
     * @returns {boolean}
     */
    get hasRTCOpen() {
        return (
            this.emitingRTCConnection?.active ||
            this.receivingRTCConnection?.active
        )
    }

    /**
     * Determine if the headset is in streaming state, use in view
     * @returns {boolean}
     */
    get isStreaming() {
        return this.isConnected && !this.streamable
    }

    /**
     * Determine if the headset is in idle state, use in view
     * @returns {false|*|boolean}
     */
    get isIdle() {
        /**
         * state.idle can be not defined on legacy headsets versions
         * @type {*|boolean}
         */
        const idleValue =
            this.entries && STATE_ENTRY_IDENTIFIERS.IS_IDLE in this.entries
                ? this.entries[STATE_ENTRY_IDENTIFIERS.IS_IDLE]
                : false
        return this.isConnected && idleValue
    }

    /**
     * get the emiting peer use to send instruction throug RTC dataChannel
     * @returns {RTCConnection}
     * */
    get emitingPeer() {
        return this.emitingRTCConnection
    }

    /**
     * @returns {RTCConnection}
     * */
    get receivingPeer() {
        return this.receivingRTCConnection
    }

    /**
     * Get the state chips of the headset, use in views
     * @returns {*[]}
     */
    get statesChips() {
        let chips = []
        if (this.isStreaming && !this.hasRTCOpen)
            chips.push('headsets.pilotage.in_streaming')
        if (this.isIdle) chips.push('headsets.pilotage.idle')
        if (this.isInFormation && !this.hasRTCOpen)
            chips.push('headsets.pilotage.in_formation')
        return chips
    }

    /**
     * get the priority state chip, use in views
     * @returns {String}
     */
    get stateChip() {
        return this.statesChips[0]
    }

    get isInFormation() {
        return Object.keys(this.currentFormation).length > 0
    }

    static getInstance(serialNumber, ...args) {
        if (this.instances.has(serialNumber))
            return this.instances.get(serialNumber)
        return new Headset(serialNumber, ...args)
    }

    /**
     * Set up the RTCConnections to the headset (emiting and receiving)
     * @returns {Promise<Awaited<void>[]>}
     */
    async initRTCConnection() {
        function initEmiting() {
            return new Promise((resolve, reject) => {
                try {
                    if (this.emitingRTCConnection.isClosed()) {
                        this.emitingRTCConnection.connect()
                        this.emitingRTCConnection.once(RTCEvent.READY, resolve)
                    } else resolve()
                } catch (error) {
                    console.error(error)
                    reject(error)
                }
            })
        }

        function initReceiving() {
            return new Promise((resolve, reject) => {
                try {
                    if (this.receivingRTCConnection.isClosed()) {
                        this.receivingRTCConnection.connect()
                        this.receivingRTCConnection.on(
                            RTCEvent.STREAM,
                            this.stream.handle.bind(this.stream)
                        )
                        //on n'attend pas le ready state puisque nous n'utilisons pas cette peer pour envoyer des données
                        resolve()
                    } else resolve()
                } catch (error) {
                    reject(error)
                }
            })
        }

        return await Promise.all([
            initEmiting.call(this),
            initReceiving.call(this),
        ])
    }

    /**
     * Close the RTCConnections to the headset (emiting and receiving)
     *
     */
    closeRTCConnection() {
        if (this.emitingRTCConnection) {
            this.emitingRTCConnection?.destroy()
        }

        if (this.receivingRTCConnection) {
            this.receivingRTCConnection.destroy()
        }
    }

    /**
     * Call when an RaPeer connection cannot be established, and we want to abort the stream and close the stream
     * @returns {Promise<void>}
     */
    async abortStream() {
        const globalSnackBar = useGlobalSnackBar()
        globalSnackBar.showSnackBar('headsets.pilotage.errors.aborted', {
            closable: false,
            class: ['default-alert', 'right-alert'],
        })
        await this.stream.close()
    }

    /**
     * Destroy the headset (call if the headset is disconnected or if we want to disconnect it)
     * @returns {void}
     */
    destroy() {
        this.closeRTCConnection() //close before, that make sure that the stream wont try send data to a closed connection
        if (this.stream) this.stream.destroy()
    }

    /**
     * Call when we want to save setting to the headset
     * @param {HeadsetStateEntries} entries
     */
    saveState(entries = this.state.entries) {
        const payload = JSON.stringify({
            type: 'saveState',
            state: {
                entries: entries.toJSON(),
            },
            serialNumber: this.serialNumber,
        })

        const onlineWS = this.constructor?.mdmStore?.online_ws || null
        const localWS = this.constructor?.mdmStore?.local_ws || null

        try {
            if (onlineWS) {
                onlineWS.send(payload)
            }

            if (localWS) {
                localWS.send(payload)
            }
        } catch (e) {
            console.error(
                `Cannot send the states entries trough the websocket : Websockets status -> WS ONLINE ${Boolean(onlineWS)} - WS LOCAL ${Boolean(localWS)}`,
                e
            )
        }
    }

    /**
     * Send a given payload to the headset through the websocket connection
     * @param data
     * @constructor
     */
    WSsend(data) {
        this._ws.send(
            JSON.stringify({
                ...data,
                serialNumber: this.serialNumber,
            })
        )
    }

    /**
     * Send the given payload to the headset throug the emiting RTCConnection
     * @param payload
     * @returns {Promise<void>}
     */
    async send(payload) {
        if (!this.isConnected) return
        if (
            this.receivingRTCConnection.isClosed() ||
            this.emitingRTCConnection.isClosed()
        )
            await this.initRTCConnection()
        try {
            if (typeof payload === 'object') payload = JSON.stringify(payload)
            this.emitingRTCConnection.send(payload)
        } catch (error) {
            console.error(error)
            console.trace('ERROR SENDING PAYLOAD', payload)
        }
    }

    /**
     * Set the state of the formation, you need to use this method to set the state of the formation
     * @param state
     */
    setCurrentFormation(state) {
        if (state?.modules_playlist && state?.modules_playlist.length === 0)
            return
        if (!state) {
            this.stream?.setState({ pause: false }) //if the formation is stopped, we unpause to be sure that after the formation, the stream will be ready
            for (let prop in this.currentFormation)
                delete this.currentFormation[prop]
        }

        this.currentFormation = { ...this.currentFormation, ...state }
    }

    /**
     * Set the state of the headset, you need to use this method to set the state of the headset
     * Usefull to format the state before set it, and save it locally in tablet mode
     *
     * @param state
     * @param silent
     */
    setState(state, silent = false) {
        if (!silent) this.emit('state', state)
        /*if (state.formationsReadyToStart) {
            state.formationsReadyToStart = state.formationsReadyToStart
                // .filter(
                //     (formation) =>
                //         formation?.content_type === undefined ||
                //         (['rab', 'video360'].includes(formation.content_type) &&
                //             (formationsIds
                //                 ? formationsIds.includes(formation.id)
                //                 : true))
                // )
                .map((formation) =>
                    JSONParseSelectedProperties(formation, [
                        'name',
                        'description',
                        'modules.name',
                        'modules.description',
                        'pack.name',
                        'objectives',
                    ])
                )
        }
        */

        if (state.currentFormation) {
            this.setCurrentFormation(state.currentFormation)
        } else {
            this.setCurrentFormation(null) //reset formation state
        }

        let hasRTCOpen = this.emitingPeer?.active || this.receivingPeer?.active

        const entries = HeadsetStateEntries.createFromArray(state.entries)

        const idle = entries[STATE_ENTRY_IDENTIFIERS.IS_IDLE] ?? false
        const streamable = entries[STATE_ENTRY_IDENTIFIERS.STREAMABLE] ?? false
        let needUndrive =
            !hasRTCOpen &&
            (streamable !== this.entries[STATE_ENTRY_IDENTIFIERS.STREAMABLE] ||
                idle)

        if (needUndrive) {
            this.state = { ...this.state, ...state }
            this.constructor.mdmStore.setHeadsetsAppDriven()
        } else {
            this.state = { ...this.state, ...state }
        }
        this.entries.readStateEntries(entries)
        this.saveStateLocally()
    }

    /**
     * Save the state of the headset in the local database
     */
    async saveStateLocally() {
        let db = Database.getInstance()
        let exists = await db
            .tables(SyncedHeadsets.storeName)
            .findOne({ serialNumber: this.serialNumber })

        if (exists) {
            let stateToSave = { ...JSON.parse(exists.state), ...this.state }
            await exists.update({ state: JSON.stringify(stateToSave) })
        }
    }

    /**
     * Send action to the headset to go back to the hub
     */
    goToHub() {
        this.WSsend({ type: 'goBackToHub' })
    }

    /**
     * Check if the user linked to the headset is the same as the given user
     * Warning : when the headset is in LAN mode, the user in the headset is not defined
     * @param user
     * @returns {boolean}
     */
    isSameUser(user) {
        return this.user?.id === user?.id
    }

    get networkInformations() {
        return {
            name:
                this.entries?.[STATE_ENTRY_IDENTIFIERS.NETWORK_NAME] ??
                'Unknown',
            ipAddress:
                this.entries?.[STATE_ENTRY_IDENTIFIERS.NETWORK_IP] ?? 'Unknown',
            macAddress:
                this.entries?.[STATE_ENTRY_IDENTIFIERS.NETWORK_MAC] ??
                'Unknown',
        }
    }

    get controllerInformations() {
        return [
            this.entries[STATE_ENTRY_IDENTIFIERS.BATTERY_LEFT_LEVEL]
                ? {
                      side: i18n.global.t(
                          'headsets.properties.controller.values.left'
                      ),
                      _side: 'left',
                      [STATE_ENTRY_IDENTIFIERS.BATTERY_LEFT_STATE]:
                          this.entries[
                              STATE_ENTRY_IDENTIFIERS.BATTERY_LEFT_STATE
                          ],
                      [STATE_ENTRY_IDENTIFIERS.BATTERY_LEFT_LEVEL]:
                          this.entries[
                              STATE_ENTRY_IDENTIFIERS.BATTERY_LEFT_LEVEL
                          ],
                  }
                : null,
            this.entries[STATE_ENTRY_IDENTIFIERS.BATTERY_RIGHT_LEVEL]
                ? {
                      side: i18n.global.t(
                          'headsets.properties.controller.values.right'
                      ),
                      _side: 'right',
                      [STATE_ENTRY_IDENTIFIERS.BATTERY_RIGHT_STATE]:
                          this.entries[
                              STATE_ENTRY_IDENTIFIERS.BATTERY_RIGHT_STATE
                          ],
                      [STATE_ENTRY_IDENTIFIERS.BATTERY_RIGHT_LEVEL]:
                          this.entries[
                              STATE_ENTRY_IDENTIFIERS.BATTERY_RIGHT_LEVEL
                          ],
                  }
                : null,
        ].filter(Boolean)
    }

    get batteryLevel() {
        return this.entries[STATE_ENTRY_IDENTIFIERS.BATTERY_LEVEL] || 100
    }

    is(headset) {
        return headset.serialNumber === this.serialNumber
    }

    toggleTag(tag) {
        if (this.hasTag(tag)) this.removeTag(tag)
        else this.addTag(tag)
    }
    addTag(tag) {
        if (!Object.values(HEADSET_TAGS).includes(tag))
            throw new Error('Invalid tag')
        this.tags.add(tag)
    }
    removeTag(tag) {
        if (!Object.values(HEADSET_TAGS).includes(tag))
            throw new Error('Invalid tag')
        this.tags.delete(tag)
    }

    hasTag(tag) {
        return this.tags.has(tag)
    }

    get formations() {
        if (this.entries)
            return HeadsetFormationsCollection.create(
                this.constructor.mdmStore.companyLicenses,
                this.entries[STATE_ENTRY_IDENTIFIERS.ACTIVATED_FORMATIONS] ||
                    [],
                this.entries[STATE_ENTRY_IDENTIFIERS.INSTALLED_FORMATIONS] ||
                    [],
                this.entries[STATE_ENTRY_IDENTIFIERS.INSTALLING_FORMATIONS] ||
                    []
            )
        console.warn('You try to load headsets formations without settings')
        return new HeadsetFormationsCollection(
            this.constructor.mdmStore.companyLicenses,
            [],
            [],
            []
        )
    }

    fetchFormations(activatedStateEntries = this.entries) {
        const activatedSlugs =
            activatedStateEntries[
                STATE_ENTRY_IDENTIFIERS.ACTIVATED_FORMATIONS
            ] || []
        if (this.entries) {
            return HeadsetFormationsCollection.create(
                this.constructor.mdmStore.companyLicenses,
                activatedSlugs,
                this.entries[STATE_ENTRY_IDENTIFIERS.INSTALLED_FORMATIONS] ||
                    [],
                this.entries[STATE_ENTRY_IDENTIFIERS.INSTALLING_FORMATIONS] ||
                    []
            )
        }
        console.warn('You try to load headsets formations without settings')
        return new HeadsetFormationsCollection(
            this.constructor.mdmStore.companyLicenses,
            [],
            [],
            []
        )
    }
}
