import { Structure } from '@libs/Structures/Structure.js'
import { useGlobalEnv } from '@store/globalEnv.js'
import { GlobalStatStructure } from '@libs/Structures/GlobalStatStructure.js'
import { LearnerProvider } from '@core/api/providers/LearnerProvider.js'
import { LicensesProvider } from '@core/api/providers/LicensesProvider.js'

export class LearnerStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    set is_vr(value) {
        this._is_vr = value ? 1 : 0
    }

    get is_vr() {
        return Boolean(this._is_vr)
    }

    set is_elearning(value) {
        this._is_elearning = value ? 1 : 0
    }

    get is_elearning() {
        return Boolean(this._is_elearning)
    }

    set notify(value) {
        this._notify = value ? 1 : 0
    }

    get notify() {
        return Boolean(this._notify)
    }

    get json() {
        return {
            ...this,
            is_vr: this._is_vr,
            is_elearning: this._is_elearning,
            notify: this._notify,
        }
    }

    get globalStats() {
        return new GlobalLearnerStatsStructure(this.stats.global).grid
    }

    static getDefault() {
        const globalEnv = useGlobalEnv()
        const structure = new LearnerStructure()
        return structure.patch({
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            user_id: null,
            formation_ids: [-1],
            // group_id: null,
            notify: false,
            is_vr: globalEnv.isVR,
            is_elearning: globalEnv.isEl,
            group: [],
        })
    }

    async archive() {
        try {
            this.loading = true
            await LearnerProvider.updateLearner(this.id, {
                archived: !this.archived,
            }).call()
        } catch (error) {
            console.error("Erreur lors de l'archivage de l'apprenant :", error)
        } finally {
            this.loading = false
        }
    }

    async delete() {
        try {
            await LearnerProvider.deleteLearner(this.id).call()
        } catch (error) {
            console.error(
                "Erreur lors de la suppression de l'apprenant :",
                error
            )
        }
    }

    async fetchLicenses() {
        try {
            await LicensesProvider.getLicenses(this.company.id, {
                licenseTypes: [7, 8, 5, 6],
            }).call()
        } catch (error) {}
    }
}

class GlobalLearnerStatsStructure extends GlobalStatStructure {
    constructor(...args) {
        super(...args)
    }

    get grid() {
        return [
            {
                icon: 'fa-calendar',
                label: this.$t(this.globalTradKey + '.session_count'),
                value: this.toInt(this.sessions_count),
            },
            {
                icon: 'fa-success-average',
                label: this.$t(this.globalTradKey + '.success_average'),
                value: this.toPercent(this.mean_result),
            },
            {
                icon: 'fa-time-spent',
                label: this.$t(this.globalTradKey + '.time_spent'),
                value: this.toDuration(this.time_passed),
            },
            {
                icon: 'fa-completion',
                label: this.$t(this.globalTradKey + '.completion_rate'),
                value: this.toPercent(this.completion_rate),
            },
        ]
    }
}
