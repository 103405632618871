<script>
    import All from '@assets/images/MDM/learnerAndUse.png'
    import Free from '@assets/images/MDM/use.png'
    import Guided from '@assets/images/MDM/learner.png'
    import HeadsetSettingItem from './HeadsetSettingItem.vue'
    import HeadsetSettingTitle from './HeadsetSettingTitle.vue'
    import HeadsetSettingInput from './HeadsetSettingInput.vue'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers'
    //eslint-disable vue/no-v-html
    export default {
        components: {
            HeadsetSettingItem,
            HeadsetSettingTitle,
            HeadsetSettingInput,
        },
        props: {
            modelValue: {
                type: Number,
                default: null,
            },
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                },
            },
            STATE_ENTRY_IDENTIFIERS() {
                return STATE_ENTRY_IDENTIFIERS
            },
            disclaimer() {
                let disclaimers = {
                    0: this.$t(
                        'headsets.settings.headset_formation_settings.start_mode.disclaimers.all'
                    ),
                    1: this.$t(
                        'headsets.settings.headset_formation_settings.start_mode.disclaimers.free'
                    ),
                    2: this.$t(
                        'headsets.settings.headset_formation_settings.start_mode.disclaimers.learner'
                    ),
                }

                return disclaimers[this.value]
            },
            image() {
                let images = {
                    0: All,
                    1: Free,
                    2: Guided,
                }
                return images[this.value]
            },
        },
    }
</script>
<template>
    <HeadsetSettingItem v-bind="$attrs">
        <HeadsetSettingTitle>
            {{
                $t(
                    'headsets.settings.headset_formation_settings.start_mode.label'
                )
            }}
        </HeadsetSettingTitle>
        <HeadsetSettingInput
            :setting-key="STATE_ENTRY_IDENTIFIERS.LAUNCH_TRAINING"
        >
            <ra-tab-select
                v-model="value"
                :options="[
                    {
                        label: $t(
                            'headsets.settings.headset_formation_settings.start_mode.values.all'
                        ),
                        icon: 'fa-learners',
                        value: 0,
                        iconOptions: {
                            weight: 'bold',
                        },
                    },
                    {
                        label: $t(
                            'headsets.settings.headset_formation_settings.start_mode.values.free'
                        ),
                        icon: 'fa-eye',
                        value: 1,
                        iconOptions: {
                            weight: 'bold',
                        },
                    },
                    {
                        label: $t(
                            'headsets.settings.headset_formation_settings.start_mode.values.learner'
                        ),
                        icon: 'fa-graduation-cap',
                        value: 2,
                        iconOptions: {
                            weight: 'bold',
                        },
                    },
                ]"
            />
            <ra-spacer />
            <ra-stack
                direction="horizontal"
                bg="vr-purple"
                padding="4"
                rounded
                alignement="center"
                mobile-swap
            >
                <ra-typo>
                    <span v-html="disclaimer" />
                </ra-typo>
                <ra-image
                    :src="image"
                    height="200px"
                />
            </ra-stack>
        </HeadsetSettingInput>
    </HeadsetSettingItem>
</template>
