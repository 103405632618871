<script>
    export default {
        props: {
            checked: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:checked'],

        methods: {
            toggle() {
                this.$emit('update:checked', !this.checked)
            },
        },
    }
</script>

<template>
    <ra-icon
        role="checkbox"
        :aria-checked="checked"
        tabindex="0"
        :icon="checked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
        v-bind="$attrs"
        @keypress.space.prevent="toggle"
        @click="toggle"
    />
</template>

<style scoped lang="scss"></style>
