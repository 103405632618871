<script>
    import HeadsetStreamControls from './HeadsetStreamControls.vue'
    import FormationModuleProgression from './FormationModuleProgression.vue'
    import MDMDebug from './MDMDebug.vue'
    import HeadsetStatusNotReachable from '@components/Headsets/Pilotage/Players/StatusViews/HeadsetStatusNotReachable.vue'
    import HeadsetStatusIdle from '@components/Headsets/Pilotage/Players/StatusViews/HeadsetStatusIdle.vue'
    import HeadsetStatusConnecting from '@components/Headsets/Pilotage/Players/StatusViews/HeadsetStatusConnecting.vue'
    import { Headset } from '@libs/MDM/core/Headset.js'

    export default {
        name: 'HeadsetVideoStream',
        components: {
            HeadsetStatusConnecting,
            HeadsetStatusIdle,
            HeadsetStatusNotReachable,
            FormationModuleProgression,
            HeadsetStreamControls,
            MDMDebug,
        },
        props: {
            headset: {
                type: Headset,
                default: null,
            },
        },
        data() {
            return {
                debugMDMactive: false,
            }
        },

        watch: {
            headset(h) {
                if (!h) return
                this.headset.stream.attach(this, this.$refs['video-container'])
            },
        },
        mounted() {
            if (!this.headset?.stream) return
            this.headset.stream.attach(this, this.$refs['video-container'])
        },
    }
</script>
<template>
    <div class="headset-video-stream-container">
        <div
            v-if="headset"
            class="text-white"
        >
            <span
                ref="video-container"
                :style="{ opacity: headset.isIdle ? 0 : 1 }"
            />
            <headset-status-not-reachable :headset="headset" />
            <headset-status-idle :headset="headset" />
            <headset-status-connecting :headset="headset" />
            <HeadsetStreamControls
                v-if="headset?.stream?.streaming"
                ref="controls"
                :headset="headset"
                @debug="debugMDMactive = !debugMDMactive"
            />
            <FormationModuleProgression
                v-if="headset?.stream?.streaming"
                :stream="headset.stream"
            />
            <MDMDebug
                ref="monitoring"
                v-model="debugMDMactive"
                :headset="headset"
            />
        </div>
        <span v-else>
            {{ $t('headsets.pilotage.no_stream_headset_selected') }}
        </span>
    </div>
</template>
<style lang="scss">
    .mdm-stream-fullscreen {
        position: absolute !important;
        height: 100% !important;
        inset: 0;
        z-index: 100;
    }

    .headset-video-stream-container {
        position: relative;
        background: #dcdced;
        color: var(--primary-lighten);
        width: 100%;
        height: 100%;
        display: flex;

        justify-content: center;
        align-items: center;

        .headset-status-overlay {
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(9, 9, 84, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        > div {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .no-cast-warn {
            display: block;
            position: absolute;
            z-index: 1;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            text-align: center;
        }

        video {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: black;
        }
    }

    [data-device='web'] .video-stream-started{
        background: var(--grey-700) !important;
    }
</style>
