<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import StartFormationAddHeadsetDialog from '@components/Headsets/StartFormation/StartFormationAddHeadsetDialog.vue'
    export default {
        components: { StartFormationAddHeadsetDialog },
        mixins: [mixins.RaDialogsMixin],
        methods: {
            handleClick() {
                this.openDialog('dialog-add-headset')
            },
        },
    }
</script>

<template>
    <ra-stack
        v-ripple="{ class: 'text-primary' }"
        padding="3,5"
        direction="horizontal"
        disabled="center"
        rounded
        class="start-formation-add-headset-dialog-opener"
        alignement="center"
        @click="handleClick"
    >
        <!-- Selection headset Image -->
        <ra-typo class="text-center">
            <ra-icon>mdi-plus</ra-icon>
            {{ $t('headsets.actions.add') }}
        </ra-typo>
    </ra-stack>
    <ra-dialog ref="dialog-add-headset">
        <start-formation-add-headset-dialog />
    </ra-dialog>
</template>

<style scoped lang="scss">
    .start-formation-add-headset-dialog-opener {
        border: var(--primary-lighten-5) 1px dashed;
        color: var(--primary-lighten-5);
        cursor: pointer;
        font-weight: 500;
        transition: 0.2s;
        &:hover {
            opacity: 0.8;
            border-color: transparent;
            background: var(--primary-lighten-9);
            color: var(--primary);
        }
    }
</style>
