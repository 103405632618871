<script>
    import { Headset, HEADSET_ACTIONS } from '@libs/MDM/core/Headset'
    import { mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { useAppStore } from '@store/app.js'

    export default {
        name: 'HeadsetStreamControls',
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },
        emits: ['debug'],
        data() {
            return {
                HEADSET_ACTIONS,
                controls: [],
            }
        },
        computed: {
            ...mapState(useUserStore, ['isGod']),
            ...mapState(useAppStore, ['isTablet']),
        },
        watch: {
            headset: {
                handler() {
                    this.controls = this.getControls()
                },
                deep: true,
            },
        },
        mounted() {
            this.controls = this.getControls()
        },
        methods: {
            getControls() {
                const pauseBTN =
                    this.headset.currentFormation.current_module !== undefined
                        ? [
                              this.headset.stream.state?.pause
                                  ? {
                                        icon: 'mdi-play',
                                        action() {
                                            this.resume()
                                        },
                                    }
                                  : {
                                        icon: 'mdi-pause',
                                        action() {
                                            this.pause()
                                        },
                                    },
                          ]
                        : []
                return [
                    ...pauseBTN,

                    this.headset.stream.state?.sound
                        ? {
                              icon: 'mdi-volume-high',
                              action() {
                                  this.setSoundMute(true)
                              },
                          }
                        : {
                              icon: 'mdi-volume-mute',
                              action() {
                                  this.setSoundMute(false)
                              },
                          },
                    // TODO: uncomment when microphone is implemented for next version
                    // this.headset.stream.state?.micro
                    //   ? {
                    //       icon: "mdi-microphone",
                    //       action() {
                    //         this.setMicrophoneMute(true);
                    //       },
                    //     }
                    //   : {
                    //       icon: "mdi-microphone-off",
                    //       action() {
                    //         this.setMicrophoneMute(false);
                    //       },
                    //     },
                ]
            },
        },
    }
</script>

<template>
    <div>
        <div class="headset-stream-controls">
            <ra-stack direction="horizontal">
                <span class="chip bg-primary-lighten-5">
                    <v-icon> $headset-stream </v-icon>
                    <ra-typo
                        bold
                        max-width="100%"
                        no-word-wrap
                    >
                        {{ headset.name }}
                    </ra-typo>
                </span>
                <v-btn
                    v-for="control of controls"
                    :key="control.icon"
                    :icon="control.icon"
                    class="text-primary-lighten-5 bg-white"
                    density="compact"
                    variant="tonal"
                    @click="control.action.call(headset.stream)"
                />
                <v-btn
                    v-if="isTablet"
                    :icon="
                        !headset.stream.isFullScreen
                            ? 'mdi-fullscreen'
                            : 'mdi-fullscreen-exit'
                    "
                    class="text-primary-lighten-5 bg-white"
                    density="compact"
                    variant="tonal"
                    @click="headset.stream.toggleFullscreen()"
                />
            </ra-stack>
        </div>

        <!--  Debug btn -->
        <v-btn
            v-if="isGod"
            class="debug"
            color="yellow"
            icon="mdi-bug"
            variant="tonal"
            @click="$emit('debug')"
        ></v-btn>
    </div>
</template>

<style lang="scss">
    .headset-stream-controls {
        z-index: 10;
        padding: 0.5em 1em;
        border-radius: 100px;
        background-color: var(--primary);
        position: absolute;
        left: 1em;
        max-width: calc(100% - 2em);
        top: 1em;

        .chip {
            width: 200px;
            padding: 0.25em 1em;
            border-radius: 100px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 8px;

            i {
                flex: 0 0 auto;

                svg {
                    width: 100%;
                }
            }

            span {
                max-width: calc(100% - 30px);
            }

            p {
                display: block;
                line-height: 1;
            }
        }
    }

    .debug {
        position: absolute;
        z-index: 10;
        bottom: 1em;
        right: 1em;
    }
</style>
