import {Structure} from "@libs/Structures/Structure.js";

export class PackStructure extends Structure{
    constructor(...args) {
        super(...args);
    }


    get gradientCSSStyle(){
        return `linear-gradient(90deg, ${this?.left_color} 0%, ${this?.right_color} 100%)`
    }

    get gradientCSSStyleReversed(){
        return `linear-gradient(90deg, ${this?.right_color} 0%, ${this?.left_color} 100%)`
    }

    get gradientVerticalCSSStyle(){
        return `linear-gradient(180deg, ${this?.left_color} 0%, ${this?.right_color} 100%)`
    }

    get gradientVerticalCSSStyleReversed(){
        return `linear-gradient(180deg, ${this?.right_color} 0%, ${this?.left_color} 100%)`
    }

    get gradientCSSVars(){
        return {
            "--gradient-left-color": this?.left_color,
            "--gradient-right-color": this?.right_color
        }
    }
}