import { Collection } from '@libs/Collections/Collection.js'
import { PacksProvider } from '@providers/PacksProvider.js'
import { PackStructure } from '@libs/Structures/PackStructure.js'

export class PackCollection extends Collection {
    constructor(packs) {
        super(packs)
        this.setFetcher(PacksProvider.getPacks.bind(PacksProvider)) //set default api provider
    }

    getPackStructure(index) {
        const item = this[index]
        if (!item) {
            throw new Error(`Out-of-bounds index: ${index}`)
        }
        return new PackStructure(item)
    }
}
