import { Collection } from '@libs/Collections/Collection.js'

export class FormationModuleCollection extends Collection {
    getByNumber(number) {
        return this.find((module) => module.number === number)
    }

    getById(id) {
        return this.find((module) => module.id === id)
    }
}
