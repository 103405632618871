<script>
    import Help from '@ui/Help.vue'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'
    import { HeadsetSettingSession } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'
    import HeadsetSettingItem from './HeadsetSettings/HeadsetSettingItem.vue'
    import HeadsetSettingTitle from './HeadsetSettings/HeadsetSettingTitle.vue'
    import HeadsetSettingInput from './HeadsetSettings/HeadsetSettingInput.vue'
    import LaunchModeHeadsetSetting from './HeadsetSettings/LaunchModeHeadsetSetting.vue'
    /* eslint-disable vue/no-v-html */
    /* eslint-disable vue/no-mutating-props */

    export default {
        name: 'HeadsetSettings',
        components: {
            Help,
            HeadsetSettingItem,
            HeadsetSettingTitle,
            HeadsetSettingInput,
            LaunchModeHeadsetSetting,
        },
        props: {
            settings: {
                type: HeadsetSettingSession,
                required: true,
            },
            direction: {
                type: String,
                default: 'horizontal',
            },
            onlySettings: {
                type: Array,
                default: () => [],
            },
            hideSettings: {
                type: Array,
                default: () => [],
            },
            noPadding: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                needPassword: false,
            }
        },
        computed: {
            SETTINGS_IDENTIFIERS() {
                return STATE_ENTRY_IDENTIFIERS
            },

            cardPadding() {
                return this.noPadding ? '0' : undefined
            },
        },

        methods: {
            needDisplay(settingIdentifier) {
                if (Array.isArray(settingIdentifier)) {
                    return settingIdentifier.some((settingIdentifier) =>
                        this.needDisplay(settingIdentifier)
                    )
                }
                if (this.hideSettings.includes(settingIdentifier)) {
                    return false
                }
                if (this.onlySettings.length === 0) {
                    return true
                }
                return this.onlySettings.includes(settingIdentifier)
            },
        },
    }
</script>

<template>
    <ra-stack
        v-if="settings"
        :direction="direction"
        padding="0,6"
        gap="8"
        mobile-swap
        v-bind="$attrs"
    >
        <ra-stack>
            <headset-setting-item :hidden-settings="hideSettings">
                <headset-setting-title>
                    {{
                        $t(
                            'headsets.settings.headset_formation_settings.kiosk_mode.password.title'
                        )
                    }}
                    <template #help>
                        <p
                            v-html="
                                $t(
                                    'headsets.settings.headset_formation_settings.kiosk_mode.password.tooltip'
                                )
                            "
                        />
                    </template>
                </headset-setting-title>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.KIOSK_MODE"
                >
                    <ra-stack gap="4">
                        <v-switch
                            v-model="needPassword"
                            color="primary"
                            density="compact"
                            hide-details
                            :label="
                                $t(
                                    'headsets.settings.headset_formation_settings.kiosk_mode.password.personalize_label'
                                )
                            "
                        />

                        <ra-box v-if="needPassword">
                            <ra-input
                                name="kioskPassword"
                                :label="
                                    $t(
                                        'headsets.settings.headset_formation_settings.kiosk_mode.password.label'
                                    )
                                "
                            >
                                <ra-password
                                    v-model="
                                        settings[
                                            SETTINGS_IDENTIFIERS.KIOSK_PASSWORD
                                        ]
                                    "
                                    variant="outlined"
                                    density="comfortable"
                                    :placeholder="
                                        $t(
                                            'headsets.settings.headset_formation_settings.kiosk_mode.password.placeholder'
                                        )
                                    "
                                    hide-details
                                />
                            </ra-input>

                            <v-switch
                                v-model="
                                    settings[
                                        SETTINGS_IDENTIFIERS.KIOSK_SHOW_PASSWORD
                                    ]
                                "
                                color="primary"
                                density="compact"
                                hide-details
                                :label="
                                    $t(
                                        'headsets.settings.headset_formation_settings.kiosk_mode.password.display'
                                    )
                                "
                            />
                        </ra-box>
                    </ra-stack>
                </headset-setting-input>
            </headset-setting-item>

            <launch-mode-headset-setting
                v-model="settings[SETTINGS_IDENTIFIERS.LAUNCH_TRAINING]"
                :hidden-settings="hideSettings"
            />
        </ra-stack>

        <ra-stack :gap="cardPadding">
            <headset-setting-item :hidden-settings="hideSettings">
                <headset-setting-title>
                    {{
                        $t(
                            'headsets.settings.headset_formation_settings.choice_module.title'
                        )
                    }}
                </headset-setting-title>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.LEANER_CAN_CHOOSE_MODULE"
                >
                    <v-switch
                        v-model="
                            settings[
                                SETTINGS_IDENTIFIERS.LEANER_CAN_CHOOSE_MODULE
                            ]
                        "
                        color="primary"
                        density="compact"
                        hide-details
                        :label="
                            $t(
                                'headsets.settings.headset_formation_settings.choice_module.label'
                            )
                        "
                    />
                </headset-setting-input>
            </headset-setting-item>

            <headset-setting-item :hidden-settings="hideSettings">
                <headset-setting-title>
                    {{
                        $t(
                            'headsets.settings.headset_formation_settings.quiz.label'
                        )
                    }}
                </headset-setting-title>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.QUIZ_VR"
                >
                    <v-switch
                        v-if="needDisplay(SETTINGS_IDENTIFIERS.QUIZ_VR)"
                        v-model="settings[SETTINGS_IDENTIFIERS.QUIZ_VR]"
                        color="primary"
                        density="compact"
                        hide-details
                        :label="
                            $t(
                                'headsets.settings.headset_formation_settings.quiz.vr_quiz'
                            )
                        "
                    />
                </headset-setting-input>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.QUIZ_EL"
                >
                    <v-switch
                        v-if="needDisplay(SETTINGS_IDENTIFIERS.QUIZ_EL)"
                        v-model="settings[SETTINGS_IDENTIFIERS.QUIZ_EL]"
                        color="primary"
                        density="compact"
                        hide-details
                        :label="
                            $t(
                                'headsets.settings.headset_formation_settings.quiz.el_quiz'
                            )
                        "
                    />
                </headset-setting-input>
            </headset-setting-item>

            <headset-setting-item :hidden-settings="hideSettings">
                <headset-setting-title>
                    {{
                        $t('headsets.settings.headset_synthesis_setting.title')
                    }}
                </headset-setting-title>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.SEND_SYNTHESIS_SHEET"
                >
                    <v-switch
                        v-model="
                            settings[SETTINGS_IDENTIFIERS.SEND_SYNTHESIS_SHEET]
                        "
                        color="primary"
                        density="compact"
                        hide-details
                        :label="
                            $t(
                                'headsets.settings.headset_synthesis_setting.label'
                            )
                        "
                    />
                </headset-setting-input>
            </headset-setting-item>

            <headset-setting-item :hidden-settings="hideSettings">
                <headset-setting-title>
                    {{ $t('headsets.settings.headset_auto_update.title') }}
                    <template #help>
                        <p
                            v-html="
                                $t(
                                    'headsets.settings.headset_auto_update.tooltip'
                                )
                            "
                        />
                    </template>
                </headset-setting-title>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.AUTO_UPDATE_FORMATION"
                >
                    <ra-stack direction="horizontal">
                        <ra-box>
                            <v-switch
                                v-model="
                                    settings[
                                        SETTINGS_IDENTIFIERS
                                            .AUTO_UPDATE_FORMATION
                                    ]
                                "
                                color="primary"
                                density="compact"
                                hide-details
                                :label="
                                    $t(
                                        'headsets.settings.headset_auto_update.label_formations'
                                    )
                                "
                            />
                        </ra-box>

                        <help>
                            <p
                                v-html="
                                    $t(
                                        'headsets.settings.headset_auto_update.tooltip_formations'
                                    )
                                "
                            />
                        </help>
                    </ra-stack>
                </headset-setting-input>
                <headset-setting-input
                    :setting-key="SETTINGS_IDENTIFIERS.AUTO_UPDATE_APP"
                >
                    <ra-stack direction="horizontal">
                        <ra-box>
                            <v-switch
                                v-model="
                                    settings[
                                        SETTINGS_IDENTIFIERS.AUTO_UPDATE_APP
                                    ]
                                "
                                color="primary"
                                density="compact"
                                hide-details
                                :label="
                                    $t(
                                        'headsets.settings.headset_auto_update.label_app'
                                    )
                                "
                            />
                        </ra-box>
                        <help>
                            <p
                                v-html="
                                    $t(
                                        'headsets.settings.headset_auto_update.tooltip_app'
                                    )
                                "
                            />
                        </help>
                    </ra-stack>
                </headset-setting-input>
            </headset-setting-item>
        </ra-stack>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
