import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'
import {
    HeadsetStateEntries,
    HeadsetStateEntry,
} from '@libs/MDM/core/Entries/HeadsetStateEntries.js'

export const HEADSET_SETTINGS_TEMPLATE = [
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.LAUNCH_TRAINING, 0),
    new HeadsetStateEntry(
        STATE_ENTRY_IDENTIFIERS.LEANER_CAN_CHOOSE_MODULE,
        true
    ),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.QUIZ_VR, true),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.QUIZ_EL, true),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.SEND_SYNTHESIS_SHEET, true),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.AUTO_UPDATE_FORMATION, false),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.AUTO_UPDATE_APP, false),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.HANDNESS, 1),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.INTERACTION_MODE, 0),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.LANGUAGE, 'fr'),
]

export const HEADSET_FORMATIONS_SETTINGS_TEMPLATE = [
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.LAUNCH_TRAINING, 0),
    new HeadsetStateEntry(
        STATE_ENTRY_IDENTIFIERS.LEANER_CAN_CHOOSE_MODULE,
        true
    ),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.QUIZ_VR, true),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.QUIZ_EL, true),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.SEND_SYNTHESIS_SHEET, true),
]

export const KIOSK_MODE_SETTINGS_TEMPLATE = [
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.KIOSK_MODE, false),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.KIOSK_PASSWORD, null),
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.KIOSK_SHOW_PASSWORD, true),
]

export const RENAME_HEADSET_SETTING_TEMPLATE = [
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.CUSTOM_NAME, null),
]

export const FORMATION_ACTIVATED_SETTING_TEMPLATE = [
    new HeadsetStateEntry(STATE_ENTRY_IDENTIFIERS.ACTIVATED_FORMATIONS, []),
]

export class HeadsetSettingSession extends HeadsetStateEntries {
    /**
     *
     * @param {HeadsetStateEntry[]} template
     * @param {HeadsetStateEntries} entries
     * @param {string} scope
     */
    constructor(
        template,
        entries = new HeadsetStateEntries([]),
        scope = 'global'
    ) {
        const formated = template.map((t) => {
            const entry = entries.entries.find(
                (e) => e.identifier === t.identifier
            )
            return entry
                ? entry.clone().setScope(scope).setApplyNow()
                : t.clone().setScope(scope).setApplyNow()
        })
        super(formated, scope)
    }

    /**
     * Apply and save the new HeadsetStateEntries
     * @param {Headset} headset
     */
    apply(headset) {
        headset.entries.writeStateEntries(this)
        headset.saveState(this)
    }

    extendIdentifierScopes(identifier, scopes) {
        if (!Array.isArray(scopes)) scopes = [scopes]
        let index = this.findIndexEntry(identifier, this.scope)
        if (index !== -1) {
            const entry = this.entries[index]
            scopes.forEach((scope) => {
                this.entries.push(entry.clone().setScope(scope))
            })
            this._createDynamicsEntriesProperties()
        }
    }
}

export class FormationHeadsetSessionSettings extends HeadsetSettingSession {
    isValidated = false
    apply() {
        this.isValidated = true
    }
    cancel() {
        this.isValidated = false
    }
}
