<script>
    export default {
        provide() {
            return {
                registerInput: this.registerInput,
                isHidden: this.isHidden,
            }
        },
        props: {
            hiddenSettings: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                settingInputs: [],
            }
        },

        computed: {
            isHiddenItem() {
                if (this.settingInputs.length === 0) {
                    return false
                }
                return this.settingInputs.every((input) => input.isHiddenInput)
            },
        },

        methods: {
            isHidden(settingKey) {
                if (!settingKey) return false
                return this.hiddenSettings.includes(settingKey)
            },
            registerInput(inputComponent) {
                this.settingInputs.push(inputComponent)
            },
        },
    }
</script>
<template>
    <ra-box
        v-if="!isHiddenItem"
        padding="pb-2"
        class="headset-setting-item"
    >
        <slot></slot>
    </ra-box>
</template>
