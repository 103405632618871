<script>
    import HeadsetSettings from '../HeadsetSettings.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import HeadsetFormationLibrary from '@components/Headsets/HeadsetFormationLibrary.vue'
    import { HeadsetFormationsCollection } from '@libs/Collections/HeadsetFormationsCollection.js'
    import { mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'
    import {
        FORMATION_ACTIVATED_SETTING_TEMPLATE,
        HEADSET_SETTINGS_TEMPLATE,
        HeadsetSettingSession,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'
    export default {
        components: {
            HeadsetSettings,
            HeadsetFormationLibrary,
        },
        mixins: [mixins.RaDialogMixin],
        props: {
            headsets: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                currentTab: 0,
                groupedSettings: new HeadsetSettingSession([
                    ...FORMATION_ACTIVATED_SETTING_TEMPLATE,
                    ...HEADSET_SETTINGS_TEMPLATE,
                ]),
                formationEdit: true,
                formations: new HeadsetFormationsCollection([]),
            }
        },

        computed: {
            ...mapState(useMDMStore, ['companyLicenses']),
            tabs() {
                return [
                    {
                        name: '1. ' + this.$tc('headsets.settings._name', 2),
                        component: 'headset-settings',
                        props: {
                            settings: this.groupedSettings,
                            padding: '0',
                            hideSettings: [STATE_ENTRY_IDENTIFIERS.KIOSK_MODE],
                        },
                    },
                    {
                        name:
                            '2. ' +
                            this.$tc('headsets.formations_library._name', 2),
                        component: 'headset-formation-library',
                        props: {
                            settings: this.groupedSettings,
                            formations: this.formations,
                            editOnly: true,
                            column: 3,
                        },
                    },
                ]
            },
            currentTabComponent() {
                return this.tabs[this.currentTab].component
            },
            currentTabProps() {
                return this.tabs[this.currentTab].props
            },
        },
        mounted() {
            this.fetchFormations()
        },
        methods: {
            handleSubmit() {
                for (let headset of this.headsets) {
                    this.groupedSettings.apply(headset)
                }
                this.close()
            },

            fetchFormations() {
                this.formations = HeadsetFormationsCollection.create(
                    this.companyLicenses,
                    this.groupedSettings[
                        STATE_ENTRY_IDENTIFIERS.ACTIVATED_FORMATIONS
                    ],
                    [],
                    []
                )
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('headsets.settings.grouped_dialog.title') }}
        </template>
        <ra-tabs
            v-model="currentTab"
            :tabs="tabs"
            no-router
        />
        <component
            :is="currentTabComponent"
            v-bind="currentTabProps"
            v-model:edit="formationEdit"
            @update="fetchFormations"
        />

        <template #footer>
            <ra-stack direction="horizontal">
                <ra-spacer />
                <v-btn
                    class="bg-primary"
                    @click="close"
                >
                    {{ $t('generics.cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary-gradient"
                    @click="handleSubmit"
                >
                    {{ $t('generics.apply') }}
                </v-btn>
            </ra-stack>
        </template>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
