<script>
    import { LicenseCollection } from '@libs/Collections/LicenseCollection.js'
    import { LearnerStructure } from '@libs/Structures/LearnerStructure.js'
    import { learnerAddFormInputs } from '@components/Learners/LearnerAddForm.inputs.js'
    import { CompanyStructure } from '@libs/Structures/CompanyStructure.js'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { LearnerProvider } from '@providers/LearnerProvider.js'
    import { mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'

    export default {
        name: 'LearnerAddForm',
        mixins: [mixins.RaDialogMixin],
        props: {
            company: CompanyStructure,
            licenses: LicenseCollection,
            targetDisplayUser: Object,
        },
        data() {
            return {
                learner: LearnerStructure.getDefault(),
                error: null,
            }
        },
        computed: {
            ...mapState(useUserStore, ['user']),
            inputs() {
                return learnerAddFormInputs.call(
                    this,
                    this.learner,
                    this.targetDisplayUser,
                    this.company,
                    this.licenses
                )
            },
        },
        methods: {
            async handleSubmit() {
                if (this.targetDisplayUser) {
                    this.learner.user_id = this.targetDisplayUser.id
                }
                const { data } = await LearnerProvider.createLearner(
                    this.learner.json
                )
                    .bindVue(this)
                    .call()
                this.$emit('added', data)
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('learners.actions.add') }}
        </template>
        <ra-form
            :element="learner"
            :error="error"
            :inputs="inputs"
            @submit="handleSubmit"
        />
    </ra-base-dialog>
</template>

<style lang="scss" scoped></style>
