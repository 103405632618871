import { Collection } from '@libs/Collections/Collection.js'
import { HeadsetFormationStructure } from '@libs/Structures/HeadsetFormationStructure.js'
import { PackCollection } from '@libs/Collections/PackCollection.js'

export class FormationSlugsStateArray extends Array {
    /**
     * @param slug
     * @returns {boolean}
     */
    in(slug) {
        return this.includes(slug)
    }
}

export class HeadsetFormationsCollection extends Collection {
    /**
     *
     * @param licensesCollection {LicenseCollection}
     * @param activatedFormationsSlugs {Array}
     * @param installedFormationsSlugs {Array}
     * @param installingFormationsSlugs {Array}
     */
    static create(
        licensesCollection,
        activatedFormationsSlugs,
        installedFormationsSlugs,
        installingFormationsSlugs
    ) {
        const formations = licensesCollection.formations

        const activatedFormations = FormationSlugsStateArray.from(
            activatedFormationsSlugs
        )
        const installedFormations = FormationSlugsStateArray.from(
            installedFormationsSlugs
        )
        const installingFormations = FormationSlugsStateArray.from(
            installingFormationsSlugs
        )

        return new HeadsetFormationsCollection(
            formations.map(
                (f) =>
                    new HeadsetFormationStructure(
                        f,
                        activatedFormations.in(f.slug),
                        installedFormations.in(f.slug),
                        installingFormations.in(f.slug)
                    )
            )
        )
    }

    groupByPack() {
        const packs = {}
        for (const formation of this) {
            const pack = formation.pack
            if (!(pack.id in packs)) {
                packs[pack.id] = {
                    ...pack,
                    formations: [],
                }
            }
            packs[pack.id].formations = [
                ...packs[pack.id].formations,
                formation,
            ]
        }
        return new PackCollection(Object.values(packs))
    }

    customs() {
        return new HeadsetFormationsCollection(
            this.filter((formation) => formation?.custom)
        )
    }

    notCustoms() {
        return new HeadsetFormationsCollection(
            this.filter((formation) => !formation?.custom)
        )
    }
}
