<script>
import {FormationStructure} from "@libs/Structures/FormationStructure.js";
import PackChip from "@components/Packs/PackChip.vue";
import FormationAttributeChip from "@components/Formations/FormationAttributeChip.vue";

export default {
  components: {FormationAttributeChip, PackChip},
  props:{
    formation:{
      type: FormationStructure,
      required: true
    }
  },

  computed:{
    formationModuleCount(){
      return this.formation.modules_count || this.formation.modules?.length
    }
  }
}
</script>

<template>
  <ra-stack direction="horizontal" alignement="center" class="start-formation-card"  bg="white" :style="{
      '--gradient' : formation.packStructure.gradientVerticalCSSStyle
  }">
    <ra-box clss="image-formation-container" height="80px"  width="110px" bg="tablet-light">
      <ra-image :src="formation?.image?.url + '?height=80'" height="80px" fallback="/images/placeholder.webp"/>
    </ra-box>
    <ra-stack gap='1' class="formation-content">
      <ra-typo semi>{{formation.name}}</ra-typo>
      <ra-stack gap="3" direction="horizontal">
        <pack-chip :pack="formation.pack" rounded />
        <formation-attribute-chip
            v-if="formationModuleCount"
            :icon="formation.icon"
            bg="tablet-light"
        >
          <ra-typo
              bold
              span
              uppercase
          >
            {{ formationModuleCount }}
          </ra-typo>
          <ra-typo
              span
              uppercase
          >
            {{ $tc('formations.modules._name', formationModuleCount) }}
          </ra-typo>
        </formation-attribute-chip>
        <formation-attribute-chip
            bg="tablet-light"
            icon="fa-clock"
        >
          <ra-typo
              bold
              span
              uppercase
          >
            {{ formation.nb_minutes }}
          </ra-typo>
          <ra-typo
              span
              uppercase
          >
            min
          </ra-typo>
        </formation-attribute-chip>
      </ra-stack>
    </ra-stack>
  </ra-stack>
</template>

<style  lang="scss">
.start-formation-card{
  border-radius: 4px 8px 8px 4px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &:hover{
    background: var(--primary-5-alpha) !important;
  }

  img{
    border-radius: 0 8px 8px 0;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    background: var(--gradient);
    pointer-events: none;
  }
}
</style>