import ApiClient from '../index.js'

export class LearnerProvider {
    static api = ApiClient.getInstance()
    static route = '/apprenants'
    static alerts = {
        sentEmailOnboarding() {
            return this.$t('learner_list.onboarding_dialog.send_success')
        },
        deleted() {
            return this.$t('learners.alerts.deleted')
        },
        affectedLicense() {
            return this.$t('learners.alerts.affected_license')
        },

        created(learner) {
            return this.$t('apprenant.create.confirm_add', {
                fullName: learner.firstname + ' ' + learner.lastname,
            })
        },

        updated(learner) {
            return this.$t('apprenant.create.confirm_edit', {
                fullName: learner.firstname + ' ' + learner.lastname,
            })
        },

        resendCodeAlert() {
            return this.$tc('learners.alerts.resend_code', 1)
        },
        multipleResendCodeAlert() {
            return this.$tc('learners.alerts.resend_code', 2)
        },
    }

    static userRoute(userId) {
        return `/users/${userId}/apprenants`
    }

    static getLearner(learnerID, query = {}) {
        return this.api.get(this.route + '/' + learnerID, query)
    }
    static updateLearner(learnerID, payload, query = {}) {
        return this.api.put(this.route + '/' + learnerID, payload, query)
    }

    static createLearner(payload, query = {}) {
        return this.api.post(this.route, payload, query)
    }

    static updateMultipleLearners(userId, ids, payload, query = {}) {
        return this.api.put(`${this.userRoute(userId)}/mass-update`, {
            ids,
            ...payload,
            query,
        })
    }

    static deleteLearner(id, query = {}) {
        return this.api.delete(`${this.route}/${id}`, query)
    }
    static massDeleteLearners(ids) {
        return this.api.post(`${this.route}/delete`, ids)
    }

    static getAffectFormations(id, query = {}) {
        return this.api.get(`apprenants/${id}/formations`, query)
    }

    static affectFormation(userId, payload) {
        return this.api.post(
            `${this.userRoute(userId)}/assign-formations`,
            payload
        )
    }
    static getLearners(companyID, query = {}) {
        query = {
            company_id: companyID,
            ...query,
        }
        return this.api.get(this.route, query)
    }

    static async importLearners(userId, formData, onProgress = () => {}) {
        return await this.api
            .post(
                this.userRoute(userId) + '/import',
                formData,
                {},
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress(progressEvent) {
                        onProgress(progressEvent)
                    },
                }
            )
            .call()
    }

    static async exportLearners(userId, query = {}) {
        try {
            const response = await this.api
                .get(this.userRoute(userId) + '/export', query, {
                    responseType: 'blob', // Indique que la réponse est un Blob (fichier binaire)
                })
                .call()

            const blob = new Blob([response.data], { type: 'application/pdf' })

            const url = URL.createObjectURL(blob)
            window.open(url)

            URL.revokeObjectURL(url)
        } catch (error) {
            console.error("Erreur lors de l'export des apprenants :", error)
        }
    }

    static resendCode(learnerEmail, query = {}) {
        return this.api.post(
            '/resend-learner-code',
            { email: learnerEmail },
            query
        )
    }

    static multipleResendCode(learnerEmails, query = {}) {
        return this.api.post(
            '/resend-learner-code/multiple',
            { emails: learnerEmails },
            query
        )
    }
}
