<script>
    import HeadsetSettings from '@components/Headsets/HeadsetSettings.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { FormationHeadsetSessionSettings } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    export default {
        name: 'StartFormationSettingsDialog',
        components: { HeadsetSettings },
        mixins: [mixins.RaDialogMixin],
        props: {
            settings: {
                type: FormationHeadsetSessionSettings,
                required: true,
            },
        },

        methods: {
            handleCancel() {
                this.settings.cancel()
                this.close()
            },
            handleValidate() {
                this.settings.apply()
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog padding="0">
        <template #title>
            {{
                $t(
                    'headsets.pilotage.steps.step1.settings_formation.title_card'
                )
            }}
        </template>
        <headset-settings
            :settings="settings"
            :hide-settings="['updateApp', 'updateFormation', 'kiosk']"
            direction="horizontal"
        />
        <ra-stack
            padding="6"
            direction="horizontal"
        >
            <ra-spacer />
            <v-btn
                color="primary"
                @click="handleCancel"
            >
                {{ $t('generics.cancel') }}
            </v-btn>
            <v-btn
                color="primary-gradient"
                @click="handleValidate"
            >
                {{
                    $t(
                        'headsets.pilotage.steps.step1.settings_formation.validate'
                    )
                }}
            </v-btn>
        </ra-stack>
    </ra-base-dialog>
</template>
