<script>
    import { useMDMStore } from '@store/mdm.js'
    import { mapActions, mapState } from 'pinia'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { HEADSET_TAGS } from '@libs/MDM/core/Headset.js'

    export default {
        mixins: [mixins.RaDialogMixin],
        data() {
            return {
                headsetsToDrive: [],
            }
        },
        computed: {
            ...mapState(useMDMStore, ['headsetsAppDriven', 'syncedHeadsets']),
            notDrivenHeadsets() {
                return this.syncedHeadsets.filter(
                    (h) => !this.headsetsAppDriven.includes(h)
                )
            },
        },
        methods: {
            ...mapActions(useMDMStore, ['driveHeadset']),
            drive() {
                this.headsetsToDrive.forEach((h) => {
                    this.driveHeadset(h)
                    h.addTag(HEADSET_TAGS.SET_FORMATION)
                })
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('headsets.actions.add') }}
        </template>

        {{ console.log(notDrivenHeadsets) }}
        <ra-input
            :label="$t('headsets.pilotage.steps.start_session.select_headsets')"
        >
            <v-select
                v-model="headsetsToDrive"
                :items="notDrivenHeadsets"
                multiple
                item-title="name"
                return-object
                variant="outlined"
            >
            </v-select>
        </ra-input>

        <ra-stack direction="horizontal">
            <ra-spacer />
            <v-btn
                @click="drive"
                color="primary-gradient"
            >
                Ajouter
            </v-btn>
        </ra-stack>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
