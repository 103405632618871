import { PWACacheProvider } from '@core/api/providers/PWACacheProvider'
import FormationCollection from '@libs/Collections/Formation'
import { defineStore } from 'pinia'

export const usePWACachedFormations = defineStore('pwaCachedFormations', {
    state() {
        return {
            formations: new FormationCollection(),
        }
    },

    actions: {
        async fetch() {
            try {
                const response = await PWACacheProvider.getFormations().call()
                if (response.data) {
                    this.formations = new FormationCollection(response.data)
                } else throw new Error('No data')
            } catch (e) {
                console.error(e)
            }
        },
    },
})
