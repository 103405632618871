<script>
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import { Collection } from '@libs/Collections/Collection.js'
    import { mapActions, mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import WifiIcon from '@assets/icons/WifiIcon.vue'
    import AffectGroupDialog from '@components/Groups/AffectGroupDialog.vue'
    import { HeadsetProvider } from '@providers/HeadsetProvider.js'
    import AffectGroupMixin from '@mixins/AffectGroupMixin.js'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'
    import { PaginatedCollection } from '@libs/Collections/PaginatedCollection.js'
    import HeadsetStatusCompact from '@components/Headsets/HeadsetStatusCompact.vue'
    import HeadsetControllersStatusCompact from '@components/Headsets/HeadsetControllersStatusCompact.vue'
    import GroupSelector from '@components/tools/GroupSelector.vue'
    import HeadsetGroupedConfigurationDialog from '@components/Headsets/GroupedConfiguration/HeadsetGroupedConfigurationDialog.vue'
    import HeadsetKioskModeEnabledDialog from '@components/Headsets/KioskMode/HeadsetKioskModeEnabledDialog.vue'
    /* eslint-disable vue/no-mutating-props */
    export default {
        name: 'HeadsetsTableList',
        components: {
            HeadsetKioskModeEnabledDialog,
            HeadsetGroupedConfigurationDialog,
            GroupSelector,
            HeadsetControllersStatusCompact,
            HeadsetStatusCompact,
            AffectGroupDialog,
            WifiIcon,
            BatteryLevel,
            ConnectedStatus,
        },
        mixins: [mixins.RaAlertMixin, mixins.RaDialogsMixin, AffectGroupMixin],
        props: {
            company: {
                type: Object,
                required: true,
            },
            scopedFormer: {
                type: Object,
                default: () => {},
            },
            items: {
                type: PaginatedCollection,
                default: () => [],
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['stream', 'startFormation', 'update:filters'],
        data() {
            return {
                selected: [],
                filters: {
                    models: null,
                    status: null,
                    user_ids: [],
                    group_id: null,
                },
                groups: [],
            }
        },
        computed: {
            ...mapState(useUserStore, ['user']),
            filtersOptions() {
                return {
                    status: [
                        {
                            title: this.$t(
                                'headsets.properties.status.values.connected'
                            ),
                            value: 'connected',
                        },
                        {
                            title: this.$t(
                                'headsets.properties.status.values.idle'
                            ),
                            value: 'idle',
                        },
                        {
                            title: this.$t(
                                'headsets.properties.status.values.disconnected'
                            ),
                            value: 'disconnected',
                        },
                    ],
                    models: new Collection(
                        this.items.map((item) => ({
                            title: item.model,
                            value: item.state.model,
                        }))
                    ).unique('value'),
                }
            },
            headers() {
                return [
                    {
                        title: this.$t('headsets.properties.name.label'),
                        key: 'name',
                    },
                    {
                        title: this.$tc('headsets._name'),
                        key: 'isConnected',
                        sortable: false,
                    },
                    {
                        title: this.$t('headsets.properties.controller.label'),
                        key: 'controllerInformations',
                        sortable: false,
                    },
                    {
                        title: this.$t('headsets.properties.stream.label'),
                        key: 'isDrivable',
                        sortable: false,
                        width: '20px',
                    },
                    {
                        title: this.$t('headsets.properties.user.label'),
                        key: 'user',
                    },
                    {
                        title: this.$tc('groups._name.label1', 2),
                        key: 'state.shared_group.name',
                    },
                    {
                        title: this.$tc('headsets.properties.note.label', 2),
                        key: 'note',
                        width: '20px',
                    },
                ]
            },
            groupedActions() {
                return [
                    {
                        label: this.$t('headsets.actions.stream'),
                        icon: '$headset-stream',
                        handler: (items) => {
                            this.handleStream(items)
                        },
                    },
                    {
                        label: this.$t('headsets.actions.edit_configuration'),
                        icon: 'fa-sliders-h',
                        handler: () => {
                            this.openDialog('dialog-grouped-configuration')
                        },
                    },
                    {
                        label: this.$t('headsets.actions.activate_kiosk_mode'),
                        icon: '$pergola',
                        handler: () => {
                            this.openDialog('dialog-kiosk-configuration')
                        },
                    },
                    {
                        label: this.$t('headsets.actions.start_formation'),
                        icon: 'fa-play',
                        handler: (items) => {
                            this.handleStartFormation(items)
                        },
                    },
                    this.getAffectGroupGrouppedAction(
                        'mdi-format-list-bulleted'
                    ),
                ]
            },
            itemActions() {
                return [
                    {
                        label: this.$t('headsets.actions.stream'),
                        icon: '$headset-stream',
                        handler: (item) => {
                            this.handleStream([item])
                        },
                    },
                    {
                        label: this.$t('headsets.actions.start_formation'),
                        icon: 'fa-play',
                        handler: (item) => {
                            this.handleStartFormation([item])
                        },
                    },
                    this.getAffectGroupItemAction('mdi-format-list-bulleted'),
                    {
                        label: this.$t('headsets.actions.edit'),
                        icon: 'fa-edit',
                        handler: (item) => {
                            this.$router.push(
                                {
                                    name: HeadsetRoutesNames.show,
                                    params: {
                                        serialNumber: item.serialNumber,
                                    },
                                },
                                this.$route
                            )
                        },
                    },
                    {
                        label: this.$t('headsets.actions.activate_kiosk_mode'),
                        icon: '$pergola',
                        handler: (item) => {
                            this.selected = [item]
                            this.openDialog('dialog-kiosk-configuration')
                        },
                    },
                    {
                        label: this.$t('headsets.actions.delete._name'),
                        icon: 'fa-trash',
                        handler: (item) => {
                            this.setGlobalDialog({
                                title: this.$t('headsets.actions.delete._name'),
                                message: this.$t(
                                    'headsets.actions.delete.validation'
                                ),
                                positiveButton: {
                                    label: this.$t(
                                        'headsets.actions.delete._name'
                                    ),
                                    action: async () => {
                                        await HeadsetProvider.detach(
                                            this.company.id,
                                            item.id
                                        ).call()
                                        this.$emit(
                                            'update:filters',
                                            this.filters
                                        )
                                    },
                                },
                            })
                        },
                    },
                ]
            },
        },
        watch: {
            filters: {
                handler() {
                    this.$emit('update:filters', this.filters)
                },
                deep: true,
            },
        },
        created() {
            if (this.scopedFormer) {
                this.filters.user_ids = [this.scopedFormer.id]
            }
        },
        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            handleStream(headsets) {
                this.$emit('stream', headsets, () =>
                    this.$router.push({
                        name: HeadsetRoutesNames.drive,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                )
            },
            handleStartFormation(headsets) {
                this.$emit('startFormation', headsets, () =>
                    this.$router.push({
                        name: HeadsetRoutesNames.startSession,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                )
            },
            async updateHeadsets(groupId) {
                await HeadsetProvider.updateMass(
                    this.company.id,
                    this.selected.map((s) => s.serialNumber),
                    {
                        group_id: groupId,
                    }
                )
                    .call()
                    .finally(() => {
                        this.selected = []
                        this.$emit('update:filters', this.filters)
                    })
            },
            handleFetch(query) {
                this.filters = {
                    ...this.items.defaultQuery,
                    ...this.filters,
                    ...query,
                }
            },

            handleClickHeadset(e, { item }) {
                this.$router.push({
                    name: this.$route.name.replace(/index$/, 'show'),
                    params: {
                        serialNumber: item.serialNumber,
                    },
                })
            },
            goToNotes(item) {
                this.$router.push({
                    name: HeadsetRoutesNames.notes,
                    params: {
                        serialNumber: item.serialNumber,
                    },
                })
            },
        },
    }
</script>

<template>
    <div>
        <ra-dialog
            ref="dialog-grouped-configuration"
            class="large-dialog"
            @close="selected = []"
        >
            <headset-grouped-configuration-dialog :headsets="selected" />
        </ra-dialog>
        <ra-dialog
            ref="dialog-kiosk-configuration"
            class="large-dialog"
            @close="selected = []"
        >
            <headset-kiosk-mode-enabled-dialog :headsets="selected" />
        </ra-dialog>
        <ra-alert
            ref="alert"
            global
        />
        <ra-aside>
            <ra-filters>
                <ra-input name="status">
                    <v-select
                        v-model="filters.status"
                        :items="filtersOptions.status"
                        :label="$t('headsets.properties.status.label')"
                        density="compact"
                        variant="outlined"
                        clearable
                    >
                        <template #item="{ props, item }">
                            <v-list-item v-bind="props">
                                <template #prepend>
                                    <ra-icon
                                        style="font-size: 10px"
                                        :color="
                                            item.value === 'connected'
                                                ? 'green'
                                                : item.value === 'idle'
                                                  ? 'warning'
                                                  : 'primary-lighten-7'
                                        "
                                        >mdi-circle</ra-icon
                                    >
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </ra-input>
                <ra-user-selector
                    v-if="!scopedFormer"
                    v-model="filters.user_ids"
                    :company_id="company.id"
                    :label="$t('headsets.properties.user.label')"
                    :multiple="true"
                    name="users"
                    density="compact"
                />
                <group-selector
                    v-model="filters.group_id"
                    :category="2"
                    :company="
                        !scopedFormer || scopedFormer.admin
                            ? company.id
                            : undefined
                    "
                    @groups:loaded="(gprs) => (groups = gprs)"
                />
            </ra-filters>
            <!--            <ra-groups-->
            <!--                v-model="filters.group_id"-->
            <!--                :category="2"-->
            <!--                :companyId="-->
            <!--                    !scopedFormer || scopedFormer.admin ? company.id : undefined-->
            <!--                "-->
            <!--                :userId="-->
            <!--                    scopedFormer && !scopedFormer.admin-->
            <!--                        ? scopedFormer.id-->
            <!--                        : undefined-->
            <!--                "-->
            <!--                @created="-->
            <!--                    ({ name }) => alert($t('groups.alerts.added', { name }))-->
            <!--                "-->
            <!--                @deleted="-->
            <!--                    ({ name }) => alert($t('groups.alerts.deleted', { name }))-->
            <!--                "-->
            <!--                @edited="-->
            <!--                    ({ name }) => alert($t('groups.alerts.edited', { name }))-->
            <!--                "-->
            <!--                @groups:loaded="(gprs) => (groups = gprs)"-->
            <!--            />-->
        </ra-aside>
        <ra-card
            :header="{
                bg: 'primary-gradient',
            }"
        >
            <template #title>
                {{
                    $tc('headsets._table_list', Math.max(1, items.total), {
                        count: items.total,
                    })
                }}
            </template>
            <template #right>
                <v-btn
                    density="compact"
                    icon
                    variant="plain"
                    @click="handleFetch({})"
                >
                    <ra-icon>mdi-refresh</ra-icon>
                </v-btn>
            </template>
            <ra-table
                v-model="selected"
                v-model:items-per-page="items.perPage"
                :grouped-actions="groupedActions"
                :headers="headers"
                :item-actions="itemActions"
                :items="items"
                :items-length="items.total"
                :loading="loading"
                :no-data-text="$t('headsets._no_headset')"
                v-bind="$attrs"
                @update:options="handleFetch($event)"
                @click:row="handleClickHeadset"
            >
                <template #item.isConnected="{ item }">
                    <headset-status-compact :headset="item" />
                </template>
                <template #item.controllerInformations="{ item }">
                    <headset-controllers-status-compact :headset="item" />
                </template>
                <template #item.name="{ item }">
                    <ra-stack
                        direction="horizontal"
                        alignement="center"
                    >
                        <ra-typo
                            :tooltip="
                                $t('headsets.properties.kioskModeActivated')
                            "
                        >
                            <ra-icon
                                v-if="item?.entries.kioskMode"
                                size="20"
                            >
                                $pergola
                            </ra-icon>
                            <ra-box
                                v-else
                                width="20px"
                            ></ra-box>
                        </ra-typo>

                        <ra-box>
                            <ra-typo
                                max-width="150px"
                                no-word-wrap
                                semi
                            >
                                {{ item.name }}
                            </ra-typo>
                            <ra-typo
                                max-width="150px"
                                no-word-wrap
                                variant="x-small"
                            >
                                {{ item.model }}
                            </ra-typo>
                        </ra-box>
                    </ra-stack>
                </template>
                <template #item.user="{ item }">
                    {{ item.user.identity }}
                </template>
                <template #item.isDrivable="{ item }">
                    <ra-box centered-content>
                        <v-btn
                            :disabled="!item.isDrivable"
                            variant="plain"
                            class="pa-0 mx-auto d-inline-block"
                            @click.stop="handleStream([item])"
                        >
                            <ra-icon class="streamIcon">
                                $headset-stream
                            </ra-icon>
                        </v-btn>
                    </ra-box>
                </template>
                <template #item.note="{ item }">
                    <ra-box
                        v-if="item.state.notes"
                        centered-content
                        @click.stop="goToNotes(item)"
                    >
                        <ra-icon style="font-size: 18px">fa-comment</ra-icon>
                    </ra-box>
                </template>

                <template #item.state.shared_group.name="{ item }">
                    <v-chip v-if="item.state.shared_group">{{
                        item.state?.shared_group?.name
                    }}</v-chip>
                </template>
            </ra-table>
        </ra-card>

        <component
            :is="getAffectGroupDialog()"
            :ref="getAffectGroupDialogRef()"
            :groups="groups"
            @on-group-selected="updateHeadsets"
        />
    </div>
</template>
<style lang="scss">
    .streamIcon svg {
        transform: scale(2);
    }
    .large-dialog .ra-dialog {
        width: 100% !important;
        max-width: calc(100% - 48px) !important;
    }
</style>
