<script>
    import PacksFilter from '@components/Packs/PacksFilter.vue'
    import FormationGrid from '@components/Formations/FormationGrid.vue'
    import FormationCollection from '@libs/Collections/Formation.js'
    import { PackCollection } from '@libs/Collections/PackCollection.js'
    import Callout from '@ui/Callout.vue'
    import StartFormationCard from '@components/Headsets/StartFormation/StartFormationCard.vue'
    import { FormationStructure } from '@libs/Structures/FormationStructure.js'

    export default {
        name: 'HeadsetStartFormation',
        // eslint-disable-next-line vue/no-unused-components
        components: { StartFormationCard, Callout, FormationGrid, PacksFilter },
        props: {
            formations: {
                type: FormationCollection,
                default: () => [],
            },
            packs: {
                type: PackCollection,
                default: () => [],
            },
        },
        emits: ['click:formation'],
        data() {
            return {
                packsFilter: [],
            }
        },
        computed: {
            FormationStructure() {
                return FormationStructure
            },
            filteredFormation() {
                if (this.packsFilter.length === 0) {
                    return this.formations
                }
                return this.formations.filterByPack(this.packsFilter)
            },
        },
    }
</script>

<template>
    <ra-stack
        gap="6"
        height="calc(100vh - 200px)"
        padding=",2,,"
        scroll-y
    >
        <ra-typo
            semi
            with-track
        >
            {{ $t('headsets.pilotage.steps.start_session.formations.select') }}
        </ra-typo>
        <ra-box>
            <Callout>
                {{
                    $t(
                        'headsets.pilotage.steps.start_session.formations.disclaimer'
                    )
                }}
            </Callout>
        </ra-box>
        <packs-filter
            v-model:selected="packsFilter"
            :packs="packs"
        />
        <ra-box>
            <hr />
        </ra-box>
        <ra-stack v-if="filteredFormation.length > 0">
            <start-formation-card
                v-for="(formation, i) in filteredFormation"
                :key="formation.id"
                :formation="formations.getFormationStructure(i)"
                @click="
                    $emit(
                        'click:formation',
                        formations.getFormationStructure(i)
                    )
                "
                @click:formation="
                    (...args) => $emit('click:formation', ...args)
                "
            />
        </ra-stack>
        <ra-typo
            v-else
            center
            italic
            >{{ $t('headsets.pilotage.steps.step1.no_formation') }}</ra-typo
        >
    </ra-stack>
</template>
