<script>
    import UserDropDownMenu from '@components/global/Menus/UserDropDownMenu.vue'
    import { mapActions, mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { useGlobalEnv } from '@store/globalEnv.js'
    import SearchBar from '@components/global/SearchBar.vue'
    import { useFAQStore } from '@store/faqTablet.js'
    import UserAccountActivator from '@components/global/UserAccountActivator.vue'
    import BackUser from './Dialogs/BackUser.vue'
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'

    export default {
        components: {
            GlobalLanguageSelector,
            UserAccountActivator,
            SearchBar,
            UserDropDownMenu,
            BackUser,
        },
        emits: ['menu'],
        computed: {
            ...mapState(useGlobalEnv, ['availableEnv', 'globalEnv']),
            ...mapState(useUserStore, [
                'user',
                'company',
                'isAuth',
                'lastLoggedUser',
            ]),

            env: {
                get() {
                    return this.globalEnv
                },
                set(...args) {
                    return this.setGlobalEnv(...args)
                },
            },
        },
        methods: {
            ...mapActions(useGlobalEnv, ['setGlobalEnv']),
            ...mapActions(useFAQStore, ['toggleOpen']),
        },
    }
</script>
<template>
    <ra-box
        class="ra-application-header"
        padding=",8"
        shadow
        tag="header"
    >
        <!-- Web Header-->
        <web>
            <ra-stack
                alignement="center"
                direction="horizontal"
                distribution="fill-equaly"
                padding="2,"
            >
                <ra-box
                    centered-content
                    class="mobile-menu"
                >
                    <v-btn
                        color="primary"
                        icon
                        size="30"
                        variant="plain"
                        @click="$emit('menu')"
                    >
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </ra-box>

                <ra-stack
                    alignement="scretch"
                    class="search-env-container"
                    direction="horizontal"
                    distribution="fill-equaly"
                    flex="1 1 100%"
                    gap="4"
                >
                    <template v-if="availableEnv.length > 1">
                        <ra-input name="envFilterMode">
                            <v-select
                                v-model="env"
                                :items="availableEnv"
                                density="compact"
                                hide-details
                                variant="outlined"
                            ></v-select>
                        </ra-input>
                    </template>
                    <template v-else>
                        <ra-box
                            flex="0 0 auto"
                            padding="2,"
                        >
                            <ra-typo semi>{{ availableEnv[0].title }}</ra-typo>
                        </ra-box>
                    </template>
                    <ra-box flex="0 1 400px">
                        <search-bar v-if="user && user.isGod" />
                    </ra-box>
                </ra-stack>
                <ra-spacer />
                <ra-image
                    v-if="user?.company?.logo"
                    :src="user?.company?.logo.url"
                    height="45px"
                    class="rounded-lg flex-0-0 d-none d-md-block"
                />
                <user-drop-down-menu v-if="isAuth && user">
                    <template #default="{ props }">
                        <user-account-activator
                            :user="user"
                            v-bind="props"
                        />
                    </template>
                </user-drop-down-menu>
                <back-user v-if="!lastLoggedUser.isEmpty" />
            </ra-stack>
        </web>
        <!-- TabletHeader-->
        <tablet>
            <ra-stack
                direction="horizontal"
                gap="8"
                height="50px"
            >
                <img
                    alt="logo"
                    src="@assets/images/logos/ra_link_logo_full.png"
                />
                <ra-stack
                    direction="horizontal"
                    gap="0"
                    tag="nav"
                >
                    <ra-button bg="primary-lighten-5">{{
                        $t('headsets.pilotage._name')
                    }}</ra-button>
                    <!--          <ra-button bg="surface-darken-1">Bibliothèque</ra-button>-->
                </ra-stack>
                <ra-stack
                    alignement="center"
                    direction="horizontal"
                    gap="6"
                    padding="pr-2"
                >
                    <ra-spacer />
                    <global-language-selector
                        :dark="true"
                        :no-edit="true"
                    />
                    <v-btn
                        v-if="$route.name === 'headsets.index'"
                        density="comfortable"
                        icon="mdi-help"
                        size="small"
                        variant="tonal"
                        @click="toggleOpen"
                    />
                    <user-drop-down-menu>
                        <template #default="{ props }">
                            <span class="user-account">
                                <v-btn
                                    v-if="!user?.avatar"
                                    color="primary"
                                    density="comfortable"
                                    flat
                                    icon="mdi-account"
                                    size="small"
                                    v-bind="props"
                                />
                                <img
                                    v-else
                                    :src="user?.avatar?.url"
                                    v-bind="props"
                                    alt="user-avatar"
                                />
                            </span>
                        </template>
                    </user-drop-down-menu>
                </ra-stack>
            </ra-stack>
        </tablet>
    </ra-box>
</template>

<style lang="scss">
    @use '@sass/main' as main;
    .mobile-menu {
        display: none;
        @media screen and (max-width: main.$drawerBreakpoint) {
            display: block;
            flex: 0 40px;
        }
    }

    .search-env-container {
        flex: 0 1 350px;

        .ra-input-container:first-child {
            flex: 0 1 150px;
        }

        @media screen and (max-width: 340px) {
            display: none;
        }
    }

    .account-container {
        flex: 0 0 auto;
        cursor: pointer;

        .v-icon {
            display: none;
        }

        img {
            width: 45px;
            height: 45px;
            border-radius: 100%;
        }

        .ra-stack {
            justify-content: flex-end;
            flex: 0 0 auto;
        }

        @media screen and (max-width: 780px) {
            .ra-stack {
                display: none;
            }
            .v-icon {
                display: block;
            }
        }
    }

    .ra-application-header {
        .user-account {
            position: relative;
            z-index: 1;
            img {
                display: block;
                --size: 28px;
                width: var(--size);
                height: var(--size);
                border-radius: var(--size);
            }
            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                background: var(--primary-gradient);
                width: 100%;
                left: 0;
                top: 0;
                height: 100%;
                border-radius: 100%;
                transform: scale(1.15) rotate(45deg);
            }
        }

        background: white;
        position: relative;
        [data-device='tablet'] & {
            z-index: 21;
        }

        [alt='logo'] {
            max-width: 200px;
            width: 25vw;
            object-fit: contain;
        }

        nav {
            .ra-button {
                border-radius: 0;
                box-shadow: none;
                height: 100%;
                width: 200px;
            }
        }
    }
</style>
