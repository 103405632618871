export const STATE_ENTRY_IDENTIFIERS = {
    //state
    NAME: 'name',
    MODEL: 'model',

    IS_IDLE: 'isIdle',
    STREAMABLE: 'streamable',

    NETWORK_NAME: 'network.name',
    NETWORK_IP: 'network.ipAddress',
    NETWORK_MAC: 'network.macAddress',
    NETWORK_INTENSITY: 'network.intensity',

    BATTERY_LEVEL: 'batteryLevel',
    BATTERY_STATE: 'batteryState',

    BATTERY_LEFT_LEVEL: 'leftHand.batteryLevel',
    BATTERY_LEFT_STATE: 'leftHand.batteryStatus',

    BATTERY_RIGHT_LEVEL: 'rightHand.batteryLevel',
    BATTERY_RIGHT_STATE: 'rightHand.batteryStatus',

    MEMORY_HEAP_SIZE: 'memoryHeapSize',
    MEMORY_USE_SIZE: 'memoryUseSize',

    STORAGE_SIZE: 'storageSize',
    STORAGE_USE: 'storageUse',

    RELEASE_VERSION: 'release.version',
    RELEASE_NAME: 'release.name',
    RELEASE_DATE: 'release.date',
    RELEASE_SIZE: 'release.size',

    APP_LAST_STARTED: 'appLastStarted',
    ANDROID_VERSION: 'androidVersion',
    //settings

    CUSTOM_NAME: 'customName',
    ACTIVATED_FORMATIONS: 'activatedFormations',

    INSTALLED_FORMATIONS: 'installedFormations',
    INSTALLING_FORMATIONS: 'installingFormations',

    KIOSK_MODE: 'kioskMode',
    KIOSK_PASSWORD: 'customPasswordKiosk',
    KIOSK_SHOW_PASSWORD: 'kioskShowPassword',

    LAUNCH_TRAINING: 'launchMode',
    LEANER_CAN_CHOOSE_MODULE: 'learnerCanChooseModule',
    QUIZ_VR: 'quizVR',
    QUIZ_EL: 'quizEL',
    SEND_SYNTHESIS_SHEET: 'sendSynthesisSheet',

    AUTO_UPDATE_FORMATION: 'autoUpdateFormation',
    AUTO_UPDATE_APP: 'autoUpdateApp',

    INTERACTION_MODE: 'interactionMode',
    HANDNESS: 'handness',
    LANGUAGE: 'language',
}
