<script>
    export default {
        inject: {
            isHidden: {
                default: () => {
                    console.error('isHidden function not provided')
                    return false
                },
            },
            registerInput: {
                default: () => {
                    console.error('registerInput function not provided')
                },
            },
        },
        props: {
            settingKey: {
                type: String,
                default: '',
            },
        },
        computed: {
            isHiddenInput() {
                return this.isHidden(this.settingKey)
            },
        },
        mounted() {
            this.registerInput(this)
        },
    }
</script>

<template>
    <ra-box
        v-if="!isHiddenInput"
        class="headset-setting-input"
    >
        <slot></slot>
    </ra-box>
</template>

<style lang="scss">
    .headset-setting-input .v-switch .v-selection-control {
        min-height: 0px;
    }

    .headset-setting-input + .headset-setting-input {
        margin-top: 16px;
    }

    .headset-setting-input .v-selection-control__wrapper {
        height: 20px;
    }
</style>
