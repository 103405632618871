<script>
    import { Headset } from '@libs/MDM/core/Headset.js'
    import KioskModeBlockOverlay from '@components/Headsets/KioskMode/KioskModeBlockOverlay.vue'
    import HeadsetFormationLibrary from '@components/Headsets/HeadsetFormationLibrary.vue'
    import {
        FORMATION_ACTIVATED_SETTING_TEMPLATE,
        HeadsetSettingSession,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'

    export default {
        components: { HeadsetFormationLibrary, KioskModeBlockOverlay },
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },

        data() {
            return {
                settings: new HeadsetSettingSession(
                    FORMATION_ACTIVATED_SETTING_TEMPLATE,
                    this.headset.entries
                ),
                formations: this.headset.fetchFormations(this.settings),
                edit: false,
            }
        },
        computed: {
            STATE_ENTRY_IDENTIFIERS() {
                return STATE_ENTRY_IDENTIFIERS
            },
        },

        methods: {
            fetch() {
                this.formations = this.headset.fetchFormations(this.settings)
            },
            submit() {
                this.settings.apply(this.headset)
                this.edit = false
            },
        },
    }
</script>

<template>
    <ra-box padding="0,6">
        <KioskModeBlockOverlay
            v-if="headset.entries[STATE_ENTRY_IDENTIFIERS.KIOSK_MODE]"
        />

        <headset-formation-library
            v-model:edit="edit"
            :settings="settings"
            :formations="formations"
            @update="fetch"
        />

        <ra-stack
            v-if="edit"
            margin="mt-6"
            direction="horizontal"
            distribution="between"
            mobile-swap
        >
            <v-btn
                color="primary"
                variant="outlined"
            >
                <ra-stack direction="horizontal">
                    <ra-icon>mdi-plus</ra-icon>
                    {{
                        $t('headsets.formations_library.actions.add_formation')
                    }}
                </ra-stack>
            </v-btn>

            <v-btn
                color="primary-gradient"
                variant="flat"
                @click="submit"
            >
                <ra-stack direction="horizontal">
                    <ra-icon>fa-check</ra-icon>
                    {{ $t('headsets.formations_library.actions.save') }}
                </ra-stack>
            </v-btn>
        </ra-stack>
    </ra-box>
</template>

<style lang="scss">
    .no-event {
        pointer-events: none;
    }
</style>
