<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import FormationAttributeChip from '@components/Formations/FormationAttributeChip.vue'
    import PackChip from '@components/Packs/PackChip.vue'
    import StartFormationModuleCard from '@components/Headsets/StartFormation/StartFormationModuleCard.vue'
    import StartFormationSettingsDialog from '@components/Headsets/StartFormation/StartFormationSettingsDialog.vue'
    import { mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'
    import { FormationHeadsetSessionSettings } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    export default {
        name: 'SelectModule',
        components: {
            StartFormationSettingsDialog,
            StartFormationModuleCard,
            PackChip,
            FormationAttributeChip,
        },
        mixins: [mixins.RaTabsMixin, mixins.RaDialogsMixin],
        props: {
            formation: {
                type: Object,
                default: () => {},
            },
            modulesSelected: {
                type: Array,
                default: () => [],
            },
            settings: {
                type: FormationHeadsetSessionSettings,
                required: true,
            },
        },
        emits: ['update:modulesSelected', 'start-formation', 'goBack'],
        data() {
            return {
                currentTab: 0,
                selectionMode: false,
            }
        },
        computed: {
            ...mapState(useMDMStore, ['headsetsAppDriven']),
            value: {
                get() {
                    return this.modulesSelected
                },
                set(value) {
                    this.$emit('update:modulesSelected', value)
                },
            },
            formationModuleCount() {
                return (
                    this.formation.modules_count ||
                    this.formation.modules?.length
                )
            },
        },
        watch: {
            selectionMode(value) {
                if (!value) {
                    this.value = []
                }
            },
        },
        methods: {
            toggleSelectionMode() {
                this.selectionMode = !this.selectionMode
            },
            toggleModule(module) {
                if (this.selectionMode === false) return
                if (this.isSelected(module)) {
                    this.value = this.value.filter((m) => m.id !== module.id)
                } else {
                    this.value.push(module)
                }
            },
            isSelected(module) {
                return this.value.includes(module)
            },
        },
    }
</script>

<template>
    <ra-box
        bg="white"
        rounded
    >
        <ra-stack
            class="select-module-header"
            bg="purple"
            direction="horizontal"
            alignement="center"
            padding="4"
            gap="6"
        >
            <v-btn
                variant="outlined"
                style="padding: 0; width: 36px"
                @click="$emit('goBack')"
            >
                <ra-icon size="20"> mdi-arrow-left </ra-icon>
            </v-btn>
            <ra-stack>
                <ra-typo
                    variant="title"
                    semi
                >
                    {{ formation.name }}
                </ra-typo>
                <ra-stack
                    gap="3"
                    direction="horizontal"
                >
                    <pack-chip
                        :pack="formation.pack"
                        rounded
                    />
                    <formation-attribute-chip
                        v-if="formationModuleCount"
                        :icon="formation.icon"
                        bg="tablet-light"
                    >
                        <ra-typo
                            bold
                            span
                            uppercase
                        >
                            {{ formationModuleCount }}
                        </ra-typo>
                        <ra-typo
                            span
                            uppercase
                        >
                            {{
                                $tc(
                                    'formations.modules._name',
                                    formationModuleCount
                                )
                            }}
                        </ra-typo>
                    </formation-attribute-chip>
                    <formation-attribute-chip
                        bg="tablet-light"
                        icon="fa-clock"
                    >
                        <ra-typo
                            bold
                            span
                            uppercase
                        >
                            {{ formation.nb_minutes }}
                        </ra-typo>
                        <ra-typo
                            span
                            uppercase
                        >
                            min
                        </ra-typo>
                    </formation-attribute-chip>
                </ra-stack>
            </ra-stack>
        </ra-stack>

        <ra-stack padding="6">
            <ra-stack direction="horizontal">
                <ra-spacer />
                <ra-typo
                    underline
                    no-word-wrap
                    class="clickable"
                    @click="toggleSelectionMode"
                >
                    {{
                        selectionMode
                            ? $t(
                                  'headsets.pilotage.steps.start_session.module.end_select'
                              )
                            : $t(
                                  'headsets.pilotage.steps.start_session.module.select'
                              )
                    }}
                </ra-typo>
            </ra-stack>
            <hr />
            <ra-box class="module-grid-container">
                <start-formation-module-card
                    v-for="module in formation.modules"
                    :key="module.id"
                    :module="module"
                    :select-mode="selectionMode"
                    :selected="isSelected(module)"
                    @click="toggleModule(module)"
                />
            </ra-box>
            <ra-stack
                direction="horizontal"
                distribution="between"
                padding="4,"
            >
                <v-btn
                    variant="outlined"
                    @click="openDialog('dialog-formation-settings')"
                >
                    <ra-icon class="pr-2"> fa-sliders-h </ra-icon>
                    {{ $t('headsets.settings._name') }}
                </v-btn>
                <v-btn
                    color="primary-gradient"
                    variant="flat"
                    @click="$emit('start-formation')"
                >
                    <ra-icon
                        class="pr-2"
                        size="12"
                        fill
                    >
                        fa-play
                    </ra-icon>
                    {{ $t('headsets.pilotage.steps.step1.start_formation') }}
                </v-btn>
            </ra-stack>
        </ra-stack>
        <ra-dialog
            ref="dialog-formation-settings"
            class="start-formation-settings-dialog"
        >
            <start-formation-settings-dialog :settings="settings" />
        </ra-dialog>
    </ra-box>
</template>
<style lang="scss">
    .start-formation-settings-dialog .ra-dialog {
        width: 100% !important;
        max-width: calc(100% - 48px) !important;
    }
</style>
<style lang="scss" scoped>
    .module-grid-container {
        margin-top: 16px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2em;
    }
</style>
