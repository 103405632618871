<script>
    import { Headset } from '@libs/MDM/core/Headset.js'
    import HeadsetSettings from '@components/Headsets/HeadsetSettings.vue'
    import KioskModeBlockOverlay from '@components/Headsets/KioskMode/KioskModeBlockOverlay.vue'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'
    import {
        KIOSK_MODE_SETTINGS_TEMPLATE,
        HEADSET_SETTINGS_TEMPLATE,
        HeadsetSettingSession,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    export default {
        components: { KioskModeBlockOverlay, HeadsetSettings },
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },
        data() {
            return {
                settings: new HeadsetSettingSession(
                    HEADSET_SETTINGS_TEMPLATE,
                    this.headset.entries
                ),
            }
        },
        computed: {
            SETTINGS_IDENTIFIERS() {
                return STATE_ENTRY_IDENTIFIERS
            },
        },
        methods: {
            handleSubmit() {
                this.settings.apply(this.headset)
            },
            handleDisabledKioskMode() {
                let settings = new HeadsetSettingSession(
                    KIOSK_MODE_SETTINGS_TEMPLATE,
                    undefined,
                    'kiosk'
                )
                settings.extendIdentifierScopes(
                    STATE_ENTRY_IDENTIFIERS.KIOSK_MODE,
                    'global'
                )
                settings.apply(this.headset)
            },
        },
    }
</script>

<template>
    <ra-box class="headset-settings-tab">
        <kiosk-mode-block-overlay
            v-if="headset.entries.kioskMode"
            @disable="handleDisabledKioskMode"
        />
        <headset-settings
            :settings="settings"
            direction="vertical"
            :hide-settings="[SETTINGS_IDENTIFIERS.KIOSK_MODE]"
        />
        <ra-box padding="4,,,6">
            <v-btn
                color="primary-gradient"
                variant="flat"
                @click="handleSubmit"
            >
                {{ $t('generics.save') }}
            </v-btn>
        </ra-box>
    </ra-box>
</template>
<style lang="scss">
    .headset-settings-tab {
        .headset-setting-item {
            background: white;
            border-radius: 8px;
            .headset-setting-title {
                padding-top: 1em;
                .ra-box {
                    padding: 0 1em;
                }
            }

            > *:not(.headset-setting-title) {
                padding: 0 1em;
            }

            padding-bottom: 1em !important;
        }
    }
</style>
