<script>
    import { mapActions, mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { useAppStore } from '@store/app.js'
    import { MyFormationsRoutesName } from '@routes/Authentificated/MyFormations.names.js'

    export default {
        name: 'UserDropDownMenu',
        computed: {
            ...mapState(useAppStore, ['isTablet']),
            ...mapState(useUserStore, ['isAuth']),
            items() {
                if (this.isFormationPlayer) {
                    return [
                        {
                            title: this.$t('formations.actions.quit'),
                            icon: 'mdi-logout',
                            visible: this.isAuth,
                            handler: () => this.goToFormation(),
                        },
                    ]
                }
                return [
                    {
                        title: this.$t('users.my-account._name'),
                        icon: 'mdi-account-outline',
                        visible: this.isAuth && !this.isTablet,
                        handler: () => this.$router.push('/my-account'),
                    },
                ]
            },
            isFormationPlayer() {
                return this.$route.name == MyFormationsRoutesName.play
            },
            visibleItems() {
                return this.items.filter((item) => item.visible)
            },
        },
        methods: {
            ...mapActions(useUserStore, ['logout']),
            goToFormation() {
                return this.$router.push({
                    name: MyFormationsRoutesName.show,
                    params: {
                        formation_id: this.$route.params.formation_id,
                    },
                })
            },
            goToLogin() {
                this.$router.push('/')
            },
            quitOfflineMode() {
                localStorage.removeItem('keepOffline')
                this.$router.push({
                    name: 'login',
                    query: { login: true },
                })
            },
        },
    }
</script>

<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <slot v-bind="{ props }"></slot>
        </template>
        <v-list>
            <v-list-item
                v-for="(item, index) in visibleItems"
                :key="index"
                @click="item.handler"
            >
                <ra-stack direction="horizontal">
                    <v-icon
                        color="primary"
                        size="small"
                        @click="item.handler()"
                        >{{ item.icon }}</v-icon
                    >
                    <ra-typo>{{ item.title }}</ra-typo>
                </ra-stack>
            </v-list-item>
            <template v-if="isAuth && !isFormationPlayer">
                <v-divider />
                <v-list-item @click="logout(goToLogin)">
                    <ra-stack
                        direction="horizontal"
                        data-test="logout"
                    >
                        <v-icon
                            color="primary"
                            size="small"
                            >mdi-logout</v-icon
                        >
                        <ra-typo>
                            {{ $t('global.auth.logout') }}
                        </ra-typo>
                    </ra-stack>
                </v-list-item>
            </template>
            <template v-else-if="!isFormationPlayer">
                <v-list-item @click="quitOfflineMode">
                    <ra-stack direction="horizontal">
                        <v-icon
                            color="primary"
                            size="small"
                        >
                            mdi-login
                        </v-icon>
                        <ra-typo>
                            {{ $t('generics.login') }}
                        </ra-typo>
                    </ra-stack>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>
