<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { HeadsetFormationsCollection } from '@libs/Collections/HeadsetFormationsCollection.js'
    import HeadsetFormationLibrary from '@components/Headsets/HeadsetFormationLibrary.vue'
    import { mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'
    import HeadsetSettings from '@components/Headsets/HeadsetSettings.vue'
    import { STATE_ENTRY_IDENTIFIERS } from '@libs/MDM/core/Entries/EntriesIdentifiers.js'
    import {
        FORMATION_ACTIVATED_SETTING_TEMPLATE,
        HEADSET_SETTINGS_TEMPLATE,
        HeadsetSettingSession,
        KIOSK_MODE_SETTINGS_TEMPLATE,
    } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    export default {
        components: {
            HeadsetSettings,
            HeadsetFormationLibrary,
        },
        mixins: [mixins.RaDialogMixin],
        props: {
            headsets: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                currentTab: 0,
                settings: new HeadsetSettingSession(
                    [
                        ...KIOSK_MODE_SETTINGS_TEMPLATE,
                        ...HEADSET_SETTINGS_TEMPLATE,
                        ...FORMATION_ACTIVATED_SETTING_TEMPLATE,
                    ],
                    undefined,
                    'kiosk'
                ),
                formationEdit: true,
                formations: new HeadsetFormationsCollection(),
            }
        },
        computed: {
            ...mapState(useMDMStore, ['companyLicenses']),
            tabs() {
                return [
                    {
                        name:
                            '1. ' +
                            this.$tc('headsets.formations_library._name', 2),
                        component: 'headset-formation-library',
                        props: {
                            settings: this.settings,
                            formations: this.formations,
                            editOnly: true,
                            column: 3,
                        },
                    },
                    {
                        name: '2. ' + this.$tc('headsets.settings._name', 2),
                        component: 'headset-settings',
                        props: {
                            settings: this.settings,
                            padding: '0',
                        },
                    },
                ]
            },
            currentTabComponent() {
                return this.tabs[this.currentTab].component
            },
            currentTabProps() {
                return this.tabs[this.currentTab].props
            },
        },

        mounted() {
            this.fetchFormations()
        },

        methods: {
            handleSubmit() {
                this.settings[STATE_ENTRY_IDENTIFIERS.KIOSK_MODE] = true
                this.settings.extendIdentifierScopes(
                    STATE_ENTRY_IDENTIFIERS.KIOSK_MODE,
                    'global'
                )
                for (let headset of this.headsets) {
                    this.settings.apply(headset)
                }
                this.close()
            },
            fetchFormations() {
                this.formations = HeadsetFormationsCollection.create(
                    this.companyLicenses,
                    this.settings[STATE_ENTRY_IDENTIFIERS.ACTIVATED_FORMATIONS],
                    [],
                    []
                )
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('headsets.settings.kiosk_mode.activate_dialog_title') }}
        </template>
        <ra-tabs
            v-model="currentTab"
            :tabs="tabs"
            no-router
        />
        <component
            :is="currentTabComponent"
            v-bind="currentTabProps"
            v-model:edit="formationEdit"
            @update="fetchFormations"
        >
            <template #formation-library-start>
                <ra-typo semi>
                    {{
                        $t(
                            'headsets.settings.kiosk_mode.select_formation_disclaimer'
                        )
                    }}
                </ra-typo>
            </template>
        </component>

        <template #footer>
            <ra-stack direction="horizontal">
                <v-btn
                    v-if="currentTab !== 0"
                    variant="outlined"
                    color="primary"
                    @click="
                        () => {
                            currentTab = currentTab - 1
                            formationEdit = true
                        }
                    "
                >
                    <template #prepend>
                        <ra-icon>mdi-chevron-left</ra-icon>
                    </template>
                    {{ $t('generics.prevStep') }}
                </v-btn>
                <ra-spacer />
                <template v-if="currentTab === 0">
                    <v-btn
                        variant="outlined"
                        color="primary"
                        @click="
                            () => {
                                currentTab = currentTab + 1
                            }
                        "
                    >
                        <template #append>
                            <ra-icon>mdi-chevron-right</ra-icon>
                        </template>
                        {{ $t('generics.nextStep') }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        class="bg-primary-gradient"
                        @click="handleSubmit"
                    >
                        {{ $t('generics.apply') }}
                    </v-btn>
                </template>
            </ra-stack>
        </template>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
