export class HeadsetStateEntry {
    constructor(
        identifier,
        value,
        scope = 'global',
        appliedAt = new Date(),
        readonly = false
    ) {
        this.identifier = identifier
        this.value = value
        this.scope = scope
        this.appliedAt = appliedAt
        this.readonly = readonly
    }

    setApplyNow() {
        this.appliedAt = new Date()
        return this
    }
    setScope(scope) {
        this.scope = scope
        return this
    }

    clone() {
        return new HeadsetStateEntry(
            this.identifier,
            this.value,
            this.scope,
            this.appliedAt,
            this.readonly
        )
    }
}

export class HeadsetStateEntries {
    scope = 'global'
    constructor(entries, scope = 'global') {
        /**
         * @type {HeadsetStateEntry[]}
         */
        this.entries = entries
        this.scope = scope
        this._createDynamicsEntriesProperties()
    }

    getKey(identifier, scope) {
        let prefix = scope === this.scope ? '' : scope + '_'
        return prefix + identifier
    }
    _createDynamicsEntriesProperties() {
        for (let entry of this.entries) {
            let key = this.getKey(entry.identifier, entry.scope)
            if (Object.getOwnPropertyNames(this).includes(key)) {
                continue
            }
            Object.defineProperty(this, key, {
                get: () => entry.value,
                set: (value) => {
                    entry.value = value
                    entry.appliedAt = new Date()
                },
            })
        }
    }

    findIndexEntry(identifier, scope) {
        return this.entries.findIndex(
            (s) => s.identifier === identifier && s.scope === scope
        )
    }

    /**
     * @param {HeadsetStateEntries} headsetStateEntries
     * @param {boolean} isWrite
     */
    applyStateEntries(headsetStateEntries, isWrite = false) {
        if (!(headsetStateEntries instanceof HeadsetStateEntries))
            return console.error(
                'Cannot apply state entries, the provided object is not an instance of HeadsetStateEntries'
            )
        for (let entry of headsetStateEntries.entries) {
            let index = this.findIndexEntry(entry.identifier, entry.scope)
            if (index === -1) {
                this.entries.push(entry)
            } else {
                const currentEntry = this.entries[index]
                if (currentEntry && currentEntry.appliedAt > entry.appliedAt)
                    continue
                if (isWrite && currentEntry.readonly) continue
                let key = this.getKey(entry.identifier, entry.scope)
                this[key] = entry.value
            }
        }
        this._createDynamicsEntriesProperties()
    }

    /**
     * @param {HeadsetStateEntries} entries
     */
    readStateEntries(entries) {
        this.applyStateEntries(entries, false)
    }

    /**
     * @param {HeadsetStateEntries} entries
     */
    writeStateEntries(entries) {
        this.applyStateEntries(entries, true)
    }

    static createFromArray(entries) {
        if (!entries) return new HeadsetStateEntries([])
        return new HeadsetStateEntries(
            entries.map(
                (entry) =>
                    new HeadsetStateEntry(
                        entry.identifier,
                        entry.value,
                        entry.scope,
                        new Date(entry.appliedAt),
                        entry.readonly
                    )
            )
        )
    }

    toJSON() {
        return this.entries.map((entry) => ({
            identifier: entry.identifier,
            value: entry.value,
            scope: entry.scope,
            appliedAt: entry.appliedAt.toISOString(),
            readonly: entry.readonly,
        }))
    }
}
