<script>
    import { HeadsetStream } from '@libs/MDM/core/HeadsetStream.js'

    export default {
        props: {
            stream: {
                type: HeadsetStream,
                required: true,
            },
        },

        computed: {},
    }
</script>

<template>
    <ra-box
        v-if="stream.modulesState"
        class="current-module-stream-player"
    >
        <ra-typo
            :tooltip-options="{
                location: 'bottom end',
                maxWidth: 400,
                offset: 16,
            }"
        >
            M{{ stream.modulesState?.currentModule }}
            <template #tooltip>
                M{{ stream.modulesState?.currentModule }}/{{
                    stream.modulesState?.modules?.length
                }}
                - {{ stream.modulesState?.module?.name }}
            </template>
        </ra-typo>
    </ra-box>
</template>

<style scoped lang="scss">
    .current-module-stream-player {
        --size: 48px;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 10;
        color: white;
        overflow: hidden;
        background-color: var(--primary);
        font-weight: 600;
        border-radius: var(--size);
        width: var(--size);
        height: var(--size);
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
