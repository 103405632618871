import { FormationStructure } from '@libs/Structures/FormationStructure.js'

export class HeadsetFormationStructure extends FormationStructure {
    constructor(formationPayload, activated, installed, installing) {
        super(formationPayload)
        this.activated = activated
        this.installed = installed
        this.installing = installing
    }
}
