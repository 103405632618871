<script>
    import HeadsetsList from '@components/Headsets/HeadsetsList.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import HeadsetPilotageCard from '@components/Headsets/Pilotage/HeadsetPilotageCard.vue'
    import { HEADSET_TAGS } from '@libs/MDM/core/Headset.js'

    export default {
        name: 'StartFormationHeadsetList',
        computed: {
            HEADSET_TAGS() {
                return HEADSET_TAGS
            },
        },
        components: { HeadsetPilotageCard },
        props: {
            headsetsAppDriven: {
                type: Array,
                default: () => [],
            },
        },
    }
</script>

<template>
    <ra-stack>
        <headset-pilotage-card
            @click="() => h.toggleTag(HEADSET_TAGS.SET_FORMATION)"
            :headset="h"
            :is-selected="h.hasTag(HEADSET_TAGS.SET_FORMATION)"
            v-for="h in headsetsAppDriven"
            :key="h.serialNumber"
        />
    </ra-stack>
</template>
